import { useEffect } from 'react'
import './TextNav.css'
import ArrowIcon from '../../assets/down_arrow.svg'
import DoubleArrow from '../../assets/double_arrow.svg'
import Bullet from '../../assets/bullet.svg'
import Search from '../../assets/search.svg'
import Question from '../../assets/question.svg'
import ReactTooltip from 'react-tooltip'

export default function TextNav ({listFormat, disableFormat=false, setListFormat, handleClick}) {
    const handleListClick = () => {
        setListFormat(prev=>!prev)
    }
    useEffect(()=>{
        ReactTooltip.rebuild()
    },[listFormat])
    
    return (
        <div className='search-nav'>
            <div className='button list-toggle-icon' value="format" onClick={handleListClick}>
                <img src={Bullet} alt='T'></img>
            </div>
            <div className='button small-search-icon' value="search" onClick={(e)=>handleClick(e,'search')}>
                <img src={Search} alt='T'></img>
            </div>

            <div className='button' value="reset" onClick={(e)=>handleClick(e,'reset')}>
                <img src={DoubleArrow} alt='B'></img>
            </div>

            <div className='button' value="prev" onClick={(e)=>handleClick(e,'prev')}>
                <img className='left-arr' src={ArrowIcon} alt='P'></img>
            </div>

            <div className='button' value="rand" onClick={(e)=>handleClick(e,'rand')}>
                <img className='' src={Question} alt='?'></img>
            </div>

            <div className='button' value="next" onClick={(e)=>handleClick(e,'next')}>
                <img className='right-arr' src={ArrowIcon} alt='N'></img>
            </div>
        </div>
    )
}
import './Cell.css'
import SearchFormMin from './Search/SearchFormMin'
import TerminalList from './TerminalList'
import Note from './Note'
import {nanoid} from 'nanoid'
import { useEffect, useState } from 'react'

export default function Cell({handleCache, remove, reorder, cellId, initNote='', initSearch='', initClear=false}) {
    const [search, setSetSearch] = useState(initSearch)
    const [note, setNote] = useState(initNote.length > 0 ? initNote : '')
    const [clear, setClear] = useState(initClear)
    const [forceToggle, setForceToggle] = useState(false)
    const setSearch = (text) => {
        setClear(false)
        if (text === search){
            setForceToggle(prev=>!prev)
        }
        setSetSearch(text)
    }
    const handleNote = (text) => {
        setNote(text)
        // handleCache(cellId, {search, clear, note:text})
    }
    useEffect(()=>{
        handleCache(cellId, {search, note, clear})
    },[clear,note,search])

    const handleClick = (e)=> {
        switch (e) {
            case 'up':
                reorder([cellId,'up',nanoid()])
                break
            case 'down':
                reorder([cellId,'down',nanoid()])
                break
            case 'delete':
                remove(cellId)
                break
            default:
                break
        }
    }
    return (
        <>
            <div className='cell'>
                <SearchFormMin handleClick={handleClick} setSearch={setSearch}/>
                <TerminalList search={search} clear={clear} setClear={setClear} refresh={forceToggle} />
                <Note note={note} setNote={handleNote}/>
            </div>
        </>

    )
 }
import './TerminalList.css'
import NoteTerminal from './NoteTerminal'
import { useEffect, useState } from 'react'
import { parseSearch} from '../util/util'
import { useBook } from '../hooks/useBook'
import { useAuthContext } from '../hooks/useAuthContext'
import {useDocument} from '../hooks/useDocument'

const color = {Jesus:'gold',God:'gold',Lord:'red',lord:'gold'}

export default function TerminalList({search, clear, setClear, refresh}) {
    const {user} = useAuthContext()
    let userName = user.displayName || user.email.split('@')[0]
    userName = userName.charAt(0).toUpperCase() + userName.slice(1)
    const {document: bibleDoc} = useDocument('bibles','KJV')
    const {document: metaData} = useDocument('bibles', 'metaData')
    const [activeFilter, setActiveFIlter] = useState(null)
    const { chapterDocData, handlePageTurn, handleCursor, 
        expandCursor, cursorPointer, clearData} = useBook({}, false)
    let route = `bible/${cursorPointer.bible}/${cursorPointer.book}/${cursorPointer.chapter?.split('.')[1]}`
    if (cursorPointer.verse) {
        route += `/${cursorPointer.verse}`
    }
    // filter content
    const versesFiltered = !cursorPointer.verse && chapterDocData.verses ? {...chapterDocData.verses} : {} 
    const versePointerFrom = cursorPointer.verse 
                            ? cursorPointer.verse.includes(':') 
                                ? cursorPointer.verse.split(':')[0] 
                                : cursorPointer.verse 
                            : null
    const versePointerTo = (cursorPointer.verse && cursorPointer.verse.includes(':')) 
                            ? cursorPointer.verse.split(':').slice(-1) 
                            : versePointerFrom
    let r = versePointerFrom && chapterDocData.verses ? Object.keys(chapterDocData.verses).map((key)=>{
        if (Number(key) >= versePointerFrom && Number(key) <= versePointerTo){
            versesFiltered[key]=chapterDocData.verses[key]
        }
        return null
    }) : null

    if (activeFilter) {
        Object.keys(versesFiltered).forEach((key) => {
            if (!(versesFiltered[key].toLowerCase().includes(activeFilter.toLowerCase()))) {
                delete versesFiltered[key]
            }
        })
    }
    // if (Object.keys(versesFiltered).length === 0) {
    //     versesFiltered.z ='Z Z Z'
    // }
    useEffect(()=>{
        if (search && search.length !== 0 && search !== '---') {
            const parsed = parseSearch(search, metaData?.bookIdNames || {})
            if (typeof parsed === 'object') {
                const verseTo = parsed.verse?.includes(':') ? parsed.verse.split(':')[parsed.verse.split(':').length -1] : null
                parsed.verse = verseTo ? parsed.verse.split(':')[0] : parsed.verse
                handleCursor(parsed, verseTo, false)
                setActiveFIlter(null)
                return
            } else {
                setActiveFIlter(search)
            }
            // setSearch('')
        } else {
            setActiveFIlter(null)
        }
    },[search,refresh,metaData])

    const handleButtonClick = async (e) => {
        e.preventDefault()
        setClear(e.target.value === 'min')
        if (e.target.value === 'next'){
            await handlePageTurn('increment')
        } else if (e.target.value === 'prev') {
            await handlePageTurn('decrement')
        } else if (e.target.value === 'snip'){
        } else if (e.target.value === 'min') {
        } else if (e.target.value === 'max') {
            expandCursor(cursorPointer)
        } else {
            await handleCursor({},false,true)
        }
    }
    return (
        <div className='terminal-list'>
            <ul>
                <li className=''>
                    {<NoteTerminal
                            tag={clear ? '' : route}
                            listFormat={false}
                            blockMap={clear ? {' ':'. . .'}: versesFiltered}
                            handleClick={handleButtonClick}
                            clearTerm={()=>{}}
                            identity={userName}
                            color={color}
                        />}
                </li>
            </ul>
        </div>
    )
 }
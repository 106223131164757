import './NoteBook.css'
import Notebook from '../../components/Notebook'
import {useParams} from "react-router-dom";

export default function NoteBook(props) {
    let { id } = useParams()
    return (
        <div className='notebook-container'>
            <Notebook doc={id}/>
        </div>
    )
}
import './Settings.css'
import { useAuthContext } from "../../hooks/useAuthContext"
import { useEffect, useState } from 'react'
import { useUserUpdate } from '../../hooks/useUserUpdate'

export default function Settings() {
    const {user} = useAuthContext()
    const [username, setUsername] = useState(user.displayName||'')
    const [email, setEmail] = useState(user.email)
    const [confirmEmail, setConfirmEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState(null)
    const {update} = useUserUpdate()

    useEffect(()=>{
        setUsername(user.displayName||'')
        setEmail(user.email)
    },[user])
    const resetForm = () =>{
        setConfirmEmail('')
        setPassword('')
        setConfirmPassword('')
    }
    const handleSubmit = (e)=>{
        setError(null)
        e.preventDefault()
        const push = {}
        if (username && username.length > 3 && username !== user.displayName){
            push.username = username
        }
        if (email !== user.email){
            if (email !== confirmEmail){
                setError('Emails do not match...')
                resetForm()
                return
            } else {
                push.email = email
            }
        }
        if (password && password.length > 0){
            if (password !== confirmPassword){
                setError('Passwords do not match...')
                resetForm()
                return
            } else {
                push.password = password
            }
        }
        if (Object.keys(push).length > 0) {
            update(push)
        }
        resetForm()

    }
    return (
        <div className='settings'>
            <h2 className='notebook-title settings-title'>User Settings</h2>
            <div className='settings-content'>
                <form className='settings-form' onSubmit={handleSubmit}>
                    <label>
                        <span>username:</span>
                        <input 
                        minLength={4}
                        type="string"
                        value={username}
                        // required
                        onChange={(e)=>{setUsername(e.target.value)}}
                        placeholder={user.email}
                        />
                    </label>
                    <label>
                        <span>email:</span>
                        <input 
                        type="email"
                        // required
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        placeholder={user.email}
                        />
                    </label>
                    {email !== user.email && <label>
                        <span>confirm email:</span>
                        <input 
                        type="email"
                        // required
                        value={confirmEmail}
                        onChange={(e)=>{setConfirmEmail(e.target.value)}}
                        />
                    </label>}
                    <label>
                        <span>password:</span>
                        <input 
                        minLength={8}
                        type="password"
                        value={password}
                        placeholder='******************************'
                        // required
                        onChange={(e)=>{setPassword(e.target.value)}}
                        />
                    </label>
                    {password !== '' && <label>
                        <span>confirm password:</span>
                        <input 
                        type="password"
                        value={confirmPassword}
                        // required
                        onChange={(e)=>{setConfirmPassword(e.target.value)}}
                        />
                    </label>}
                    {error && <p className='error'>{error}</p>}
                    <button className="btn">Update</button>
                </form>
            </div>
        </div>
    )
}
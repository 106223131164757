import './NavBar.css'
import Flame from '../../assets/flame.svg'
import { useLogout } from '../../hooks/useLogout'
import { useAuthContext } from '../../hooks/useAuthContext'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

const getUn = (user) =>{
    if (!user){
        return ' '
    }
    let uName = user.displayName || user.email.split('@')[0]
    uName = uName.charAt(0).toUpperCase() + uName.slice(1)
    return uName
}

export default function NavBar() {
    const {logout, isPending} = useLogout()
    const {user} = useAuthContext()
    const [userName, setUsername] = useState(getUn(user))

    useEffect(()=>{
        setUsername(getUn(user))
    },[user])

    return (<>
        <div className='navbar'>
            <div className='nav-content'>
                <ul>
                    <Link  to='/about'className="logo">
                        <img src={Flame} alt="logo" />
                        <span>GospelStack</span>
                    </Link >
                    <li className='search-icon'>
                        <Link to='/browse'>
                            BROWSE
                            {/* <img src={Search}/> */}
                        </Link>
                    </li>
                    {!user && (
                        <>
                            <li>
                                <Link to="/login">Login</Link>
                            </li>
                            <li>
                                <Link to="/signup">Signup</Link>
                            </li>
                        </>
                    )}
                    {user && (
                        <li onClick={logout}>
                            <Link to="/">Logout</Link>
                        </li>
                    )}
                </ul>
            </div>
        </div>
        </>
    )
}
import './TopSideBar.css'
import { NavLink } from 'react-router-dom'
import Logout from '../../assets/logout.svg'
import NewIcon from '../../assets/sticky_note.svg'
import DocsIcon from '../../assets/documents_icon.svg'
import CogIcon from '../../assets/cog_icon.svg'
import KeyIcon from '../../assets/key_icon.svg'
import InfoIcon from '../../assets/info_icon.svg'
import Group from '../../assets/group.svg'
import { useLogout } from '../../hooks/useLogout'
import { useLocation } from 'react-router-dom'
export default function TopSideBar() {
    const location = useLocation()
    const {logout} = useLogout()
    return (
        <div className = 'top-sidebar'>
            <div className='top-sidebar-content'>
                <nav className='links'>
                    <ul>
                        <li className={location.pathname.includes('newnote') ? 'active-link' : ''}>
                            <NavLink to='/newnotebook'>
                                <img src={NewIcon} alt="AddIcon" />
                            </NavLink>
                        </li>
                        <li className={location.pathname[location.pathname.length-1]=='/' ? 'active-link' : ''}>
                            <NavLink exact to='/'>
                                <img src={DocsIcon} alt="AddIcon" />
                            </NavLink>
                        </li>
                        <li className={location.pathname.includes('groups') ? 'active-link' : ''}>
                            <NavLink exact to='/groups/all'>
                                <img src={Group} alt="GroupIcon" />
                            </NavLink>
                        </li>
                        <li className={location.pathname.includes('api') ? 'active-link' : ''}>
                            <NavLink to='/api'>
                                <img src={KeyIcon} alt="AddIcon" />
                            </NavLink>
                        </li>
                        <li className={location.pathname.includes('info') ? 'active-link' : ''}>
                            <NavLink to='/info'>
                                <img src={InfoIcon} alt="AddIcon" />
                            </NavLink>
                        </li>
                        <li className={location.pathname.includes('settings') ? 'active-link' : ''}>
                            <NavLink to='/settings'>
                                <img src={CogIcon} alt="SettingsIcon" />
                            </NavLink>
                        </li>
                        <li className='logout-btn' onClick={logout}>
                            <NavLink exact to='/login'>
                                <img src={Logout} alt="LogoutIcon" />
                            </NavLink>
                        </li>
                        {/* <li className='collapse' onClick={()=>{setSlim(prev=>!prev)}}>
                            <div>
                                <img src={DoubleArrow} alt="collapse" />
                            </div>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </div>
    )
}
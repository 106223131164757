import Select from 'react-select';
import { useDocument } from '../../hooks/useDocument';
import useWindowDimensions from '../../hooks/useWindowDimensions'

const selectStyles = {
    control: styles => ({ ...styles, backgroundColor: '#e5e5e5', border: '1px solid #838383', 'borderRadius':'3px' }),
    dropdownIndicator: styles => ({ ...styles, width: '30px', padding:'5px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor:  isSelected ? '#6462e9' : isFocused ? '#a2a1e6' : '#fff',
        color: isFocused || isSelected ? '#fff':'#444',
        cursor: isDisabled ? 'not-allowed' : 'default'
      }
    }
}
const selectStylesSmall = {
  ...selectStyles,
  dropdownIndicator: styles => ({ ...styles, width: '15px', padding:'0px' })
}

export default function DropSelect({options, onChange, id, placeholder='Select', value, size, unset=true}) {
    const {document: metaData} = useDocument('bibles', 'metaData')
    const { width } = useWindowDimensions()
    // if width is less than 415px... load alt styles for Select
    const preOptions = unset ? [{label:'Select',value:'Select'}] : []
    const transformValue = (value) => {
      if (id === 'chapterSelect' && value.includes('.')){
        return value.split('.')[1]
      }
      if (id === 'bookSelect' && value){
        return metaData?.bookIdNames[value] || value
      }
      return value
    }
    return <div className={size ? `select-box-${size}` :'select-box'}>
    <Select
        options={options && Object.keys(options).length > 0 ? [...preOptions,...options] : [{label:'Select',value:'Select'}]}
        defaultValue={'Select'}
        maxMenuHeight={250}
        onChange={onChange}
        id={id}
        styles={width > 415 ? selectStyles : selectStylesSmall}
        placeholder={placeholder}
        value={{label:transformValue(value)||'Select',value:value}}
    />
</div>
}
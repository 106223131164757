import './Note.css'
import React, {useCallback, useState } from 'react'
import { createEditor, Transforms, Text, Editor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import Bold from '../assets/text/bold.svg'
import Italic from '../assets/text/italic.svg'
import Highlight from '../assets/text/highlight.svg'
import Underline from '../assets/text/underline.svg'
import Bullet from '../assets/text/bullet.svg'
import Number from '../assets/text/number.svg'
// import Left from '../assets/text/left.svg'
// import Center from '../assets/text/center.svg'
// import Right from '../assets/text/right.svg'

// ordered/unordered list, align left/right/center
const Leaf = props => {
  return (
    <span
      {...props.attributes}
      style={{ 
        fontWeight: props.leaf.bold ? 'bold' : 'normal', 
        fontStyle: props.leaf.italic ? 'italic' : 'normal',
        // backgroundColor: props.leaf.highlight ? 'yellow' : '',
        backgroundColor: props.leaf.highlight ? '#ffdb4b' : '',
        textDecoration: props.leaf.underline ? 'underline' : '',
      }}
    >
      {props.children}
    </span>
  )
}

function MyEditor({note, setNote}) {
    let initialValue = note

    const renderLeaf = useCallback(props => {
      return <Leaf {...props} />
    }, [])

    if (typeof note === 'string') {
      initialValue = [
        {
          type: 'paragraph',
          children: [{ text: note }],
        },
      ]
    }
    const handleChange = (e) => {
      setNote(e)
    }

    const isMarkActive = (editor,type='bold') => {
      const [match] = Editor.nodes(editor, {
        match: n => n[type] === true,
        universal: true,
      })
  
      return !!match
    }

    const handleEdit = (e) => {
      // let's make the current text bold if the user holds command and hits "b"
      if (e === 'bold' || e.ctrlKey && e.key === 'b') {
        typeof e === 'object' && e.preventDefault()
        Transforms.setNodes(
          editor,
          { bold: isMarkActive(editor,'bold') ? null : true },
          // Apply it to text nodes, and split the text node up if the
          // selection is overlapping only part of it.
          { match: n => Text.isText(n), split: true }
        )
      } else if (e==='italic' || e.ctrlKey && e.key === 'i') {
        typeof e === 'object' && e.preventDefault()
        Transforms.setNodes(
          editor,
          { italic: isMarkActive(editor,'italic') ? null : true },
          // Apply it to text nodes, and split the text node up if the
          // selection is overlapping only part of it.
          { match: n => Text.isText(n), split: true }
        )
      } else if (e === 'highlight' || e.ctrlKey && e.key === 'h') {
        typeof e === 'object' && e.preventDefault()
        Transforms.setNodes(
          editor,
          { highlight: isMarkActive(editor,'highlight') ? null : true },
          // Apply it to text nodes, and split the text node up if the
          // selection is overlapping only part of it.
          { match: n => Text.isText(n), split: true }
        )
      } else if (e === 'underline' || e.ctrlKey && e.key === 'u') {
        typeof e === 'object' && e.preventDefault()
        Transforms.setNodes(
          editor,
          { underline: isMarkActive(editor,'underline') ? null : true },
          // Apply it to text nodes, and split the text node up if the
          // selection is overlapping only part of it.
          { match: n => Text.isText(n), split: true }
        )
      }
    }

    const [editor] = useState(() => withReact(createEditor()))
    return (
      // Add the editable component inside the context.
      <div className='editorContainer'>
        <Slate editor={editor} value={initialValue} onChange={(e)=>handleChange(e)}>
          <div className='noteTray'>
            <img onClick={()=>{handleEdit('bold')}} src={Bold} alt='B'></img>
            <img onClick={()=>{handleEdit('italic')}} src={Italic} alt='I'></img>
            <img onClick={()=>{handleEdit('underline')}} src={Underline} alt='U'></img>
            <img onClick={()=>{handleEdit('highlight')}} src={Highlight} alt='H'></img>
            {/* <img onClick={()=>{handleEdit('number')}} src={Number} alt='N'></img>
            <img onClick={()=>{handleEdit('bullet')}} src={Bullet} alt='b'></img> */}
                      <hr/>
          </div>
          <div className='editor'>
          <Editable 
          renderLeaf={renderLeaf}
          onKeyDown={(e) => {handleEdit(e)}}
          />
          </div>
        </Slate>
      </div>
    )
  } 

export default function Note({note, setNote}) {
  console.log(note)
    return <div className={`note ${note? '' : 'emptyNote' }`}>
                <form>
                    <MyEditor 
                    note={note} 
                    setNote={setNote}
                    />
                </form>
            </div>
 }
import './App.css'

import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import { useState } from 'react'

import Dashboard from './pages/dashboard/Dashboard'
import Api from './pages/api/Api'
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import NoteBook from './pages/notebook/NoteBook'
import NewNoteBook from './pages/newnotebook/NewNoteBook'
import Public from './pages/public/Public'
import NavBar from './components/Generic/NavBar'
import SideBar from './components/Generic/SideBar'
import TopSideBar from './components/Generic/TopSideBar'
import Search from './pages/search/Search'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import Settings from './pages/settings/Settings'
import Info from './pages/info/Info'
import Groups from './pages/groups/Groups'
import NotFound from './pages/notfound/NotFound'
import Reset from './pages/reset/Reset'
import {BrowseContextProvider} from './context/BrowseContext'

function App() {
  const {user, authIsReady} = useAuthContext()
  const [showSideBar, setShowSideBar] = useState(false)
  return (
    <div className="App">
      {user && <div className='topbuff'>...</div>}
      {authIsReady && (
        <BrowserRouter>
          <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
          {user && <TopSideBar />}
          <div className='content'>
          {user && <SideBar show={showSideBar}/>}
              <Switch>
              <Route path='/NewNotebook'>
                  {user &&  <NewNoteBook/>}
                  {!user &&  <Redirect to='/login'/>}
                </Route>
                <Route path='/NoteBook/:id'>
                  {user &&  <NoteBook/>}
                  {!user &&  <Redirect to='/login'/>}
                </Route>
                <BrowseContextProvider>
                  <Route exact path='/'>
                    {user &&  <Dashboard/>}
                    {!user &&  <Redirect to='/about'/>}
                  </Route>
                  <Route exact path='/about'>
                    <About/>
                  </Route>
                  <Route path='/browse'>
                    <Search/>
                  </Route>
                  <Route path='/login'>
                    {!user && <Login/>}
                    {user &&  <Redirect to='/'/>}
                  </Route>
                  <Route path='/signup'>
                    {!user && <Signup/>}
                    {user &&  <Redirect to='/'/>}
                  </Route>
                  <Route path='/reset'>
                    {!user && <Reset/>}
                    {user &&  <Redirect to='/'/>}
                  </Route>
                  <Route path='/public'>
                  <Public/>
                  </Route>
                  <Route path='/api'>
                    {user &&  <Api/>}
                    {!user &&  <Redirect to='/login'/>}
                  </Route>
                  <Route path='/contact'>
                    <Contact/>
                  </Route>
                  <Route path='/settings'>
                    {user &&  <Settings/>}
                    {!user &&  <Redirect to='/login'/>}
                  </Route>
                  <Route path='/info'>
                    <Info/>
                  </Route>
                  <Route path='/Groups/:id'>
                  {user &&  <Groups/>}
                  {!user &&  <Redirect to='/login'/>}
                  </Route>
                </BrowseContextProvider>
                <Route path='*'>
                    <NotFound/>
                  </Route>
              </Switch>
            </div>
        </BrowserRouter>
      )}
      {/* {location && location.pathname.includes('/search') && <div id="back"></div>} */}
    </div>
  );
}

export default App

import './Search.css'
import { useContext, useRef } from 'react'
import TextNav from '../../components/Search/TextNav'
import Terminal from '../../components/Search/Terminal'
import DropSelect from '../../components/Generic/DropSelect'
import ReactTooltip from 'react-tooltip'
import { useUserUpdate }from '../../hooks/useUserUpdate'
import SearchIcon from '../../assets/search.svg'
import DeleteIcon from '../../assets/delete_icon.svg'
import { BrowseContext } from '../../context/BrowseContext'

export default function Search() {
    const {
        handleSubmit,
        setVersion,
        version,
        bibleDoc,
        setBook,
        book,
        bookDoc,
        setChapter,
        chapter,
        chapterDoc,
        setVerse,
        verse,
        versions,
        setVerseTo,
        verseTo,
        setSearch,
        search,
        clearSearch,
        listFormat,
        queryResults,
        setListFormat,
        handleButtonClick,
        route,
        query,
        handleMakeSelection,
        popResults,
        chapterDocData,
        versesFiltered,
        handleExpand,
        clearData,
        color
    } = useContext(BrowseContext)
    const {savePreferences, userDoc } = useUserUpdate()
    const searchRef = useRef()
    return (
        <div className='search-page'>
            <ReactTooltip className='opaque' backgroundColor='#f1f1f1' textColor='grey' place='bottom' effect='solid'/>
            <div className='search-form-container'>
                <div className='notebook-form'>
                <form className='' onSubmit={handleSubmit}>
                    <div className='form-block'>
                    <div className='options'>
                        {/* <div className='search-title'><h2>{'/Select/'}</h2></div> */}
                        <label>
                            {/* <span>Version:</span> */}
                            <DropSelect
                                options={versions.map((ver)=>{ return {value:ver, label:ver}})}
                                unset={false}
                                onChange={(e)=>{
                                    userDoc && savePreferences('None',null, null, e.value)
                                    setVersion(e.value)
                                }}
                                id={'versionSelect'}
                                placeholder='KJV'
                                value={version}
                                size='med'
                            />
                        </label>
                        <label>
                            {/* <span>Book:</span> */}
                            <DropSelect
                                options={(bibleDoc?.bookOrder 
                                    && bibleDoc.bookOrder.map((bk)=>({value:bk,label:bibleDoc.bookIdNames[bk], key:bk}))) || []}
                                onChange={(e)=>{setBook(e.value)}}
                                id={'bookSelect'}
                                value={book}
                                size='large'
                            />
                        </label>
                        <label>
                            {/* <span>Chapter:</span> */}
                            <DropSelect
                                options={bookDoc?.chapterOrder && bookDoc.chapterOrder.map((ch)=>({value:ch,label:ch.split('.')[1]}))}
                                onChange={(e)=>{setChapter(e.value)}}
                                id={'chapterSelect'}
                                value={chapter}
                                size='small'
                            />
                        </label>
                        <label>
                        {/* <span>Verse:</span> */}
                            <DropSelect
                                options={chapterDoc?.verseOrder && chapterDoc.verseOrder.map((vs)=>({value:vs,label:vs}))}
                                onChange={(e)=>{setVerse(e.value)}}
                                id={'verseSelect'}
                                value={verse}
                                size='small'
                            />
                        </label>
                        <label>
                            {/* <span>To:</span> */}
                            <DropSelect
                                options={chapterDoc?.verseOrder && verse !== '---' 
                                && chapterDoc.verseOrder.indexOf(verse) < chapterDoc.verseOrder.length -1 
                                && chapterDoc.verseOrder.slice(
                                    chapterDoc.verseOrder.indexOf(verse)+1
                                ).map((vs)=>({value:vs,label:vs}))}
                                onChange={(e)=>{setVerseTo(e.value)}}
                                id={'verseToSelect'}
                                value={verseTo}
                                size='small'
                            />
                        </label>
                    </div>
                    <label className='center-label'>
                        {/* <span>Key words, phrases, or topics:</span> */}
                        <div className='searchBar' ref={searchRef} onClick={()=>{}}>
                            <button type='submit' className='searchIcon'><img src={SearchIcon} alt='?'></img></button>
                            <input 
                            className='searchInput'
                            type="text"
                            onChange={(e)=>setSearch(e.target.value)}
                            value={search}
                            placeholder='kjv/jhn/1/1:5'
                            spellCheck="false"
                            />
                            <button onClick={clearSearch} className='searchIcon searchClear'><img src={DeleteIcon} alt='x'></img></button>
                        </div>
                    </label>
                    </div>
                    <button className='btn large-search-btn'><img src={SearchIcon} alt='search'></img></button>
                </form>
                <TextNav listFormat={listFormat} disableFormat={queryResults} setListFormat={setListFormat} handleClick={handleButtonClick}/> 
                </div>
            </div>
            <div className='results'>
            {queryResults && (
                <>
                    {/* <h3 className='route'>
                        Query results:
                    </h3> */}
                    {queryResults.map((data, index) =>(
                        <Terminal
                        key={index}
                        route={route}
                        listFormat={true}
                        queryTerm={true}
                        blockMaps={[data]}
                        handleClick={()=>{
                            query(null)
                            handleMakeSelection(data.tag)}}  // resole data.tag to cursor, query(null), handleExpand(cursor)
                        clearTerm={() => popResults(index)}
                        color={null}
                        highlighted={data.highlight}
                        />
                    ))
                    }
                    {Object.keys(queryResults).length>=15 &&<TextNav listFormat={listFormat} setListFormat={setListFormat} handleClick={handleButtonClick} disableFormat={queryResults}/>}
                </>
            )}
            {!queryResults && chapterDocData.verses && (
                <>
                    {/* <h3 className='route'>
                        {route.toLowerCase()}
                    </h3> */}
                    <Terminal
                        identity={''}
                        route={route}
                        listFormat={listFormat}
                        blockMaps={[{tag:route.toLowerCase(),content:versesFiltered}]}
                        handleClick={handleExpand}
                        clearTerm={clearData}
                        color={color}
                     />
                     {Object.keys(versesFiltered).length>=15 &&<TextNav listFormat={listFormat} setListFormat={setListFormat} handleClick={handleButtonClick} disableFormat={queryResults}/>}
                </>
            )}
            {((queryResults && queryResults.length===0) || !chapterDocData.verses) && 
            <>
                <Terminal identity={''} tag ='/' blockMaps={versesFiltered.length === 0 ? [{tag:'/',content:{z:'No results . . .'}}] : [{tag:'/',content:{z:'z z z'}}]}/>
            </> }
            </div>
        </div>
    )
}
import './NotebookButtons.css'
import Save from "../assets/save_icon.svg"
import Add from "../assets/add_icon.svg"
import Info from "../assets/info_icon.svg"
import Note from "../assets/sticky_note.svg"
import Folder from "../assets/documents_icon.svg"
import Delete from "../assets/delete_icon.svg"
import Grid from "../assets/grid_icon.svg"
import List from "../assets/list_icon.svg"
import Reverse from "../assets/reverse.svg"
import Forward from "../assets/forward.svg"
import Visible from "../assets/visi_on.svg"
import Invisible from "../assets/visi_off.svg"
import { useHistory } from "react-router-dom"

 export default function NotebookButtons(
    {className, add, handleDelete, handleSort, handleToggle, save, title  = '', display=['add','save', 'all', 'delete']}) {
    const history = useHistory() 
    const buttons =[
        <div className='infoIcon' data-tip='info' key='info' onClick={()=>{history.push('/info')}}><img alt='info' className='btn-icon' src={Info}></img></div>,
        <div className='forward' data-tip='sort' key='forward' onClick={handleSort}><img alt='sort' className='btn-icon' src={Forward}></img></div>,
        <div className='reverse' data-tip='sort' key='reverse' onClick={handleSort}><img alt='sort' className='btn-icon' src={Reverse}></img></div>,
        <div className='list' data-tip='list' key='list' onClick={handleToggle}><img alt='list' className='btn-icon' src={List}></img></div>,
        <div className='grid' data-tip='grid' key='grid' onClick={handleToggle}><img alt='grid' className='btn-icon' src={Grid}></img></div>,
        <div className='note' data-tip='new' key='note' onClick={()=>{history.push('/newnotebook')}}><img alt='new' className='btn-icon' src={Note}></img></div>,
        <div className='add' data-tip='add' key='add' onClick={add}><img alt='add' className='btn-icon' src={Add}></img></div>,
        <div className='preview' data-tip='preview' onClick={handleToggle} key='preview'><img alt='preview' className='btn-icon' src={Visible}></img></div>,
        <div className='noprev' data-tip='preview' onClick={handleToggle} key='noprev'><img alt='noprev' className='btn-icon' src={Invisible}></img></div>,
        <div className='all' data-tip='all' onClick={()=>{history.push('/')}} key='all'><img alt='all' className='btn-icon' src={Folder}></img></div>,
        <div className='save' data-tip='save' onClick={save} key='save'><img className='btn-icon' alt='save' src={Save}></img></div>,
        <div className='delete' data-tip='delete' onClick={handleDelete} key='delete'><img alt='delete' className='btn-icon' src={Delete}></img></div>,
     ]
    return <div className={`notebook-buttons-container ${className}`}>
        <div className='notebook-buttons'>
            {title && <h5 className='page-title'>{title}</h5>}
        
                <span className='note-btn-icons'>
                    { buttons.map((button)=>{
                        return display.includes(button.props.className) && button
                    })}
                </span>
            </div>
    </div>
 }
import './SearchFormMin.css'
import './SearchForm.css'
import { useState } from 'react'
import SearchBar from '../Generic/SearchBar'
import DeleteIcon from '../../assets/delete_icon.svg'
import DownArrow from '../../assets/down_arrow.svg'

export default function SearchFormMin({setSearch, handleClick}) {
    // temp attributes filled out in form
    const[searchVal, setSearchVal] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        setSearch(searchVal)
    }
    return (<div className='notebook-header'>
        <form className='notebook-form' onSubmit={handleSubmit}>
            <div className='optionsmin'>
                <div className='cell-buttons'>
                    <div className='up-cell' onClick={()=>handleClick('up')}><img alt='up' src={DownArrow}></img></div>
                    <div className='down-cell' onClick={()=>handleClick('down')}><img alt='down'  src={DownArrow}></img></div>
                    <div className='remove-cell' onClick={()=>handleClick('delete')}><img alt='delete' src={DeleteIcon}></img></div>
                </div>
                <SearchBar search={searchVal} setSearch={setSearchVal}/>
                <button className='btn'>search</button>
            </div>
        </form>
    </div>
    )
}
import './DropModal.css'
import Cancel from '../../assets/delete_icon.svg'
import { useCollection } from '../../hooks/useCollection'
import { nanoid } from 'nanoid'
import { useState } from 'react'
export default function DropModal({setShowModal, id, height, header='modal header', collection, value}) {
    const { documents } = useCollection(collection)
    const [selected, setSelected] = useState([])

    const handleSave = async () => {
        for (const docId of selected) {
            const ref = documents.find((doc)=>doc.id==docId).ref
            const snap = await ref.get()
            await ref.update({notebookCells:[...snap.data().notebookCells,value]})
        }
        setShowModal(false)
        setSelected([])
    }
    const handleCheck = (id) => {
        setSelected((prev)=>{
            if (prev.includes(id)) {
                return prev.filter((x)=>x!==id)
            }
            return [...prev,id]
        })
    }
    return (
            <div className='modal-container drop-modal'>
                <div className='modal' style={{top:height,position:'absolute'}}>
                    <div className='modal-header'>
                        <img alt='cancel' onClick={()=>setShowModal(false)} src={Cancel}></img>
                        <h5 className='page-title'>{header}</h5>
                    </div>
                    <div className='modal-content'>
                        <ul>
                            {documents?.map((doc,index)=>(
                                <li key={nanoid()}>
                                    <label className={index%2==0 ? 'even-row' : 'odd-row'}>
                                        <input 
                                        type="checkbox" 
                                        id={doc.id} 
                                        value={doc.id} 
                                        onChange={()=>handleCheck(doc.id)} 
                                        checked={selected.includes(doc.id)}></input>
                                        <span>{doc.title}</span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='modal-buttons'>
                        <button onClick={()=>handleSave()} className='modal-deny btn danger-btn'>Save</button>
                    </div>
                </div>
            </div>
        )
 }
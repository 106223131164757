import { useState } from 'react'
import { auth } from '../../firebase/config'
import { useHistory } from 'react-router-dom'
import {useLocation} from "react-router-dom";
import AboutBottom from '../about/AboutBottom'

export default function Reset(props) {
    const [message,setMessage] = useState(null)
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const [reset, setReset] = useState(false)
    // let { mode, oobCode } = useParams()
    let location = useLocation()
    const mode = new URLSearchParams(location.search).get("mode")
    const oobCode = new URLSearchParams(location.search).get("oobCode")

    const handleSubmit = async (e) => {
        e.preventDefault() 
        const r = await auth.sendPasswordResetEmail(email)
        setEmail('')
        setMessage('email sent!')
    }
    const handleReset = async (e) =>{
        e.preventDefault()
        auth.confirmPasswordReset(oobCode,password)
            .then(()=>{
                setMessage('complete!')
            })
            .catch(err => setError(err))
    }
    if (mode === 'resetPassword' && oobCode){
        auth.verifyPasswordResetCode(oobCode)
        .then(function(resetEmail) {
            setEmail(resetEmail)
            setReset(true)
        })
        .catch(function() {
          // Invalid code
        })
    }
    if (reset){
        return (
            <div className='auth-container auth-reset'>
                <form className='auth-form' onSubmit={handleReset}>
                    <h2>Reset password</h2>
                    <label>
                        <span>Account email:</span>
                        <input 
                        type="email"
                        disabled={true}
                        value={email}
                        />
                    </label>
                    <label>
                        <span>New password:</span>
                        <input 
                        type="password"
                        onChange={(e)=>setPassword(e.target.value)}
                        value={password}
                        />
                    </label>
                    <button className="btn">Update</button>
                    {message && <p className='login-link forgot'>{message}</p>}
                    {error && <div className='error'>{error}</div>}
                    <p className='login-link' onClick={() => { history.push('/login') }}>login existing account</p>
                </form>
                <AboutBottom/>
            </div>
        )
    }
    return (
        <div className='auth-container'>
            <form className='auth-form auth-reset' onSubmit={handleSubmit}>
                <h2>Reset password</h2>
                <label>
                    <span>Account email:</span>
                    <input 
                    type="email"
                    required
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}
                    />
                </label>
                <button className="btn">Send</button>
                {message && <p className='login-link forgot'>{message}</p>}
                <p className='login-link' onClick={() => { history.push('/login') }}>login existing account</p>
            </form>
            <AboutBottom/>
        </div>
    )
}
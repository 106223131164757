import { Link } from 'react-router-dom'

const subLinks = {
    info:'/info',
    login:'/login',
    signup:'/signup',
    browse:'/browse',
    contact:'/contact'}
export default function AboutBottom() {
    return (
        <div className='about-links'>
            <div className='about-desc'>
                <p>
                    GospelStack is a data/algorithm oriented project for working with and sharing the bible. Use the "browse" page to see what content is available... Create an account to access notebooks, groups, and API.
                </p>
            </div>
            <div className='about-contact'>
                {Object.keys(subLinks).map((link)=>{return <Link key={link} to={subLinks[link]}>{link}</Link>})}
                <a href='https://github.com/aaronhough/gospel-stack'>github</a>
            </div>
        </div>
    )
}
import { useEffect, useState } from "react"
import { firestore } from "../firebase/config"
import { versions } from "../util/util"

const root = 'bibles'

const resolveCursor = async (cursor, stop=null, random=false, docs={}) => {
    const {bibleDocData, bookDocData} = docs
    // should add name/id to firestore docs/data and pass current data herew
    // if cursor is not different for a doc, we don't have to re-fetch
    if (cursor.verse && cursor.verse.includes(':')){
        stop = cursor.verse.split(':').slice(-1)[0]
        cursor.verse = cursor.verse.split(':')[0]
    }
    // should also check for non existent bible ver
    if (!cursor.bible || !versions.includes(cursor.bible)) {
        cursor.bible='KJV'
    }
    // fetch bible doc
    let bibleDoc = {}
    if (bibleDocData && cursor.bible === bibleDocData.id) {
        bibleDoc = {...bibleDocData}
    } else {
        let bibleSnap = await firestore.doc(`${root}/${cursor.bible}`).get()
        bibleDoc = bibleSnap.data()
    }
    if (random) {
        cursor.book = bibleDoc.bookOrder[Math.floor(Math.random()*bibleDoc.bookOrder.length)]
    } 
    if (!cursor.book || bibleDoc.bookOrder.indexOf(cursor.book)<0) {
        cursor.book = bibleDoc.bookOrder[0]
    }
    // fetch book doc and fit cursor for chapter
    let bookDoc = {}
    if (bookDocData && cursor.book === bookDocData.id){
        bookDoc = {...bookDocData}
    } else{
        let bookSnap = await firestore.doc(`${root}/${cursor.bible}/books/${cursor.book}`).get()
        bookDoc = bookSnap.data()
    }

    if (random) {
        cursor.chapter = bookDoc.chapterOrder[Math.floor(Math.random()*bookDoc.chapterOrder.length)]
    }
    if (Number(cursor.chapter) < 1 || (cursor.chapter !== '1^' && bookDoc.chapterOrder.indexOf(cursor.chapter)<0)) {
        cursor.chapter = bookDoc.chapterOrder[0]
    } else if (cursor.chapter === '1^') {
        cursor.chapter = bookDoc.chapterOrder[bookDoc.chapterOrder.length - 1]
    }
    // fetch verse doc and fit cursor for verse
    let chapterSnap = await firestore.doc(`${root}/${cursor.bible}/books/${cursor.book}/chapters/${cursor.chapter}`).get()
    let chapterDoc = chapterSnap.data()

    if (random) {
        cursor.verse = chapterDoc.verseOrder[Math.floor(Math.random()*chapterDoc.verseOrder.length)]
    } else if (cursor.verse){
        if (Number(cursor.verse) < 1 || (cursor.verse !== '1^' && chapterDoc.verseOrder.indexOf(cursor.verse)<0 )) {
            delete cursor.verse
            // cursor.verse = chapterDoc.verseOrder[0]
        } else if (cursor.verse === '1^') {
            cursor.verse = chapterDoc.verseOrder[chapterDoc.verseOrder.length - 1]
        }
        if (stop && cursor.verse) {
            const size = Number(stop)-Number(cursor.verse)
            const indexStop = chapterDoc.verseOrder.indexOf(stop)
            const indexVerse = chapterDoc.verseOrder.indexOf(cursor.verse)
            if (!(size < 1 || indexStop < 1 || indexVerse > indexStop)) {
                cursor.verse = `${cursor.verse}:${stop}`
            } else if (indexStop < 0 && indexVerse <= chapterDoc.verseOrder.length - 2) {
                cursor.verse = `${cursor.verse}:${chapterDoc.verseOrder[chapterDoc.verseOrder.length -1 ]}`
            }
        }
    }
    return {bibleDoc, bookDoc, chapterDoc, cursor}
}

const pageTurner = async (options) => {
    let {cursor, direction, bibleDoc, chapterDoc, bookDoc} = options
    if (cursor.verse && cursor.verse.indexOf(':') > -1) {
        cursor.verse = direction === 'increment' ? cursor.verse.split(':').slice(-1)[0] : cursor.verse.split(':')[0]
    }

    if (!(chapterDoc.verseOrder)) {
        ({cursor, chapterDoc} = await resolveCursor(cursor,null,false, {bibleDocData:bibleDoc,
                                                                        bookDocData:bookDoc,
                                                                        chapterDocData:chapterDoc}))
    }

    let {bookOrder} = bibleDoc
    let {chapterOrder} = bookDoc
    let {verseOrder} = chapterDoc

    // if we have verse in cursor, move by one verse else by one chapter
    if (direction==='increment'){
        if (cursor.verse){
            // if at the last verse
            verseOrder.indexOf(cursor.verse) === verseOrder.length -1 ?
                // if at last chapter
                chapterOrder.indexOf(cursor.chapter) === chapterOrder.length-1 ?
                    // if also at last book
                    bookOrder.indexOf(cursor.book) === bookOrder.length -1 ?
                    // move to first book/chapter/verse
                    cursor = {...cursor,book:bookOrder[0],chapter:'1',verse:'-1'} :
                    // else move to next book first chapter/verse
                    cursor = {...cursor,book: bookOrder[bookOrder.indexOf(cursor.book)+1],chapter:'1',verse:'-1'} :
                // else move to next chapter first verse
                cursor = {
                    ...cursor,chapter:chapterOrder[chapterOrder.indexOf(cursor.chapter)+1],verse:'-1'} :
            // else move to the next verse
            cursor.verse = verseOrder[verseOrder.indexOf(cursor.verse)+1]
        } else {
            // if incr by chapter and at last chapter
            chapterOrder.indexOf(cursor.chapter) === chapterOrder.length-1 ?
                // if also at last book
                bookOrder.indexOf(cursor.book) === bookOrder.length -1 ?
                // move to first book/chapter
                cursor = {...cursor,book:bookOrder[0],chapter:'1'} :
                // else move to next book first chapter
                cursor = {...cursor,book: bookOrder[bookOrder.indexOf(cursor.book)+1],chapter:'1'} :
            // else move to next chapter
            cursor.chapter =chapterOrder[chapterOrder.indexOf(cursor.chapter)+1]
        }
    } else {
        if (cursor.verse){
            // if at the first verse
            verseOrder.indexOf(cursor.verse) === 0 ?
                // if at first chapter
                chapterOrder.indexOf(cursor.chapter) === 0 ?
                    // if also at first book
                    bookOrder.indexOf(cursor.book) === 0 ?
                    // move to last book/chapter/verse
                    cursor = {...cursor,book:bookOrder[bookOrder.length-1],chapter:'1^',verse:'1^'} :
                    // else move to prev book last chapter/verse
                    cursor = {...cursor,book: bookOrder[bookOrder.indexOf(cursor.book)-1],chapter:'1^',verse:'1^'} :
                // else move to prev chapter last verse
                cursor = {
                    ...cursor,chapter:chapterOrder[chapterOrder.indexOf(cursor.chapter)-1],verse:'1^'} :
            // else move to the prev verse
            cursor.verse = verseOrder[verseOrder.indexOf(cursor.verse)-1]
        } else {
            // if decr by chapter and at first chapter
            chapterOrder.indexOf(cursor.chapter) === 0 ?
                // if also at first book
                bookOrder.indexOf(cursor.book) === 0 ?
                // move to last book/last chapter
                cursor = {...cursor,book:bookOrder[bookOrder.length-1],chapter:'1^'} :
                // else move to prev book last chapter
                cursor = {...cursor, book: bookOrder[bookOrder.indexOf(cursor.book)-1],chapter:'1^'} :
            // else move to prev chapter
            cursor.chapter = chapterOrder[chapterOrder.indexOf(cursor.chapter)-1]
        }
    }
    return cursor
    // const r = await resolveCursor(cursor)
    // return await resolveCursor(cursor)
}

function equalKeys(a, b) {
    if (!(a && b)){
        return true
    }
    var aKeys = Object.keys(a).sort();
    var bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) === JSON.stringify(bKeys);
  }
// examples

// 'test/kjv/books/john/chapter/1' verses/1

// let cursor = {
//     bible:'kjv',
//     book:'john',
//     chapter:'1',
//     verse:'1'
// }

// let bibleDoc = {bookOrder: ['matthew','mark','luke','john']}
// let bookDoc = {chapterOrder:['1','2','3']}
// let chapterDoc = {verseOrder:['1','2','3','4','5']}

export const useBook = (_cursor={}, init=true) => {
    const [cursorPointer, setCursorPointer] = useState(_cursor)
    const [bibleDocData, setBibleDocData] = useState({})
    const [bookDocData, setBookDocData] = useState({})
    const [chapterDocData, setChapterDocData] = useState({})
    // let {cursor, direction, bibleDoc, chapterDoc, bookDoc} = options
    useEffect(()=>{
        let route = `bible/${cursorPointer.bible}/${cursorPointer.book}/${cursorPointer.chapter}`
        if (cursorPointer.verse) {
            route += `/${cursorPointer.verse}`
        }
    },[chapterDocData])
    useEffect(()=>{
        const update = async () => {
            const {
                bibleDoc,
                bookDoc,
                chapterDoc,
                cursor} = await resolveCursor(cursorPointer,null,false, {bibleDocData, bookDocData, chapterDocData})
            setCursorPointer(cursor)
            setBibleDocData(bibleDoc)
            setBookDocData(bookDoc)
            setChapterDocData(chapterDoc)
        }
        if (init) {
            update()  
        }
    },[])


    const expandCursor = async (prevCursor) => {
        if (prevCursor.verse) {
            delete prevCursor.verse
        } 
        const {
            bibleDoc,
            bookDoc,
            chapterDoc,
            cursor} = await resolveCursor(prevCursor,null,false,{bibleDocData,bookDocData,chapterDocData})
        setCursorPointer(cursor)
        setBibleDocData(bibleDoc)
        setBookDocData(bookDoc)
        setChapterDocData(chapterDoc)
        return cursor
    }

    const handlePageTurn = async (direction='increment') => {
        const options = {cursor:cursorPointer, direction, bibleDoc:bibleDocData, chapterDoc:chapterDocData, bookDoc:bookDocData}
        const prevCursor = await pageTurner(options)
        const {
            bibleDoc,
            bookDoc,
            chapterDoc,
            cursor} = await resolveCursor(prevCursor,null,false,{bibleDocData,bookDocData,chapterDocData})
        setCursorPointer(cursor)
        setBibleDocData(bibleDoc)
        setBookDocData(bookDoc)
        setChapterDocData(chapterDoc)
        return cursor
    }
    const handleCursor = async (prevCursor={}, stop, random=false) => {
        const {
            bibleDoc,
            bookDoc,
            chapterDoc,
            cursor} = await resolveCursor(prevCursor, stop, random, {bibleDocData,bookDocData,chapterDocData})
        setCursorPointer(cursor)
        setBibleDocData(bibleDoc)
        setBookDocData(bookDoc)
        setChapterDocData(chapterDoc)
        return cursor
    }

    const clearData = () => {
        setChapterDocData({})
    }

    return {bibleDocData, bookDocData, chapterDocData, handlePageTurn, handleCursor, expandCursor, cursorPointer, clearData}
}
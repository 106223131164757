import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyDM_03GoEBeiK9eI9E3o8x2FYemMnJSb8w",
    authDomain: "gospel-stack.firebaseapp.com",
    projectId: "gospel-stack",
    storageBucket: "gospel-stack.appspot.com",
    messagingSenderId: "701270559710",
    appId: "1:701270559710:web:2eb3e633863be2abb0eb4f"
}

// init firebase
const app = firebase.initializeApp(firebaseConfig)
firebase.analytics()

// init services
const firestore = firebase.firestore() 
const auth = firebase.auth()
const timestamp = firebase.firestore.Timestamp
const storage = firebase.storage()
export { firestore, auth, timestamp, storage }
class MinHeap {
    constructor(){
        this.values = []
    }
    // heap related math
    parent(index) {
        return Math.floor((index -1) / 2)
    }
    leftChild(index) {
        return (index * 2) + 1
    }
    rightChild(index) {
        return (index * 2) + 2
    }
    isLeaf(index) {
        return (
            index >= Math.floor(this.values.length / 2) && index <= this.values.length - 1
        )
    }

    swap(index1, index2) {
        [this.values[index1], this.values[index2]] = [this.values[index2], this.values[index1]]
    }
    heapifyUp(index) {
        let currentIndex = index
        let parentIndex = this.parent(currentIndex)
        while (currentIndex > 0 && this.values[currentIndex].score < this.values[parentIndex].score) {
            this.swap(currentIndex, parentIndex)
            currentIndex = parentIndex
            parentIndex = this.parent(parentIndex)
        }
    }
    heapifyDown(index) {
        if (!this.isLeaf(index)) {
            let left = this.leftChild(index)
            let right = this.rightChild(index)
            let smallestIndex = index
            if (this.values[left] && this.values[left].score < this.values[smallestIndex].score) {
                smallestIndex = left
            }
            if (this.values[right] && this.values[right].score < this.values[smallestIndex].score) {
                smallestIndex = right
            }
            if (smallestIndex !== index) {
                this.swap(index,smallestIndex)
                this.heapifyDown(smallestIndex)
            }
        }
    }
    extractMin() {
        if (this.values.length === 0){
            return null
        }
        const min = this.values[0]
        const last = this.values.pop()
        this.values[0] = last
        this.heapifyDown(0)
        return min
    }
    peek() {
        return this.values[0]
    }
    add(element) {
        this.values.push(element)
        this.heapifyUp(this.values.length -1)
        // console.log(this.values)
    }
    buildHeap(array, size) {
        if (array){
            this.values = array
        }
        for(let i = Math.floor(this.values.length / 2); i >= 0; i--){
            this.heapifyDown(i);
        }
    }
    print() {
        let i = 0;
        while (!this.isLeaf(i)) {
            console.log("PARENT:", this.values[i]);
            console.log("LEFT CHILD:", this.values[this.leftChild(i)]);
            console.log("RIGHT CHILD:", this.values[this.rightChild(i)]);
            i++;
        }      
    }
}

export {MinHeap}
import { useState, useEffect } from 'react'
import { auth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import { useDocument } from './useDocument'

export const useUserUpdate = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch, user } = useAuthContext()
  const {document:userDoc, saveDocument:saveUserDoc} = useDocument('users',user?.uid || '---')

  const savePreferences = async (gridView='None',browseCursor, highlights, defaultVersion='KJV') =>{
    if (user) {
      await saveUserDoc({settings: {
        gridView: gridView!=='None' ?  gridView : userDoc.settings?.gridView || false, 
        browseCursor: browseCursor ?  browseCursor: userDoc.settings?.browseCursor || {},
        highlights: highlights?  highlights: userDoc.settings?.highlights || {},
        defaultVersion: defaultVersion?  defaultVersion: userDoc.settings?.defaultVersion || {},
      }})
    }
  }
  const update = async ({username, email, password}) => {
    if (user) {
      setError(null)
      setIsPending(true)
      try {
        // username
        let res = null
        if (username){
          res = await auth.currentUser.updateProfile({displayName: username})
        }
        // email
        if (email){
          res = await auth.currentUser.updateEmail(email)
        }
        //password
        if (password) {
          res = await auth.currentUser.updatePassword(password)
        }
  
        if (res) {
          dispatch({ type: 'UPDATE', payload: res.user })
        }
  
        if (!isCancelled) {
          setIsPending(false)
          setError(null)
        }
      } 
      catch(err) {
        if (!isCancelled) {
          setError(err.message)
          setIsPending(false)
        }
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { update, isPending, error, savePreferences, userDoc }
}
import './Modal.css'
import Cancel from '../../assets/delete_icon.svg'

export default function Modal({setShowModal, id, height, handleModalAffirm, header='modal header', message='modal message'}) {
    const handleClick = () =>{
        setShowModal(false)
    }
    const handleAffirm = () =>{
        handleModalAffirm(id)
        setShowModal(false)
    }
    return (
            <div className='modal-container'>
                <div className='modal' style={{top:height,position:'absolute'}}>
                    <div className='modal-header'>
                        <img alt='cancel' onClick={handleClick} src={Cancel}></img>
                        <h5 className='page-title'>{header}</h5>
                    </div>
                    <div className='modal-content'>
                        <p>{message}</p>
                    </div>
                    <div className='modal-buttons'>
                        <button onClick={handleAffirm} className='modal-confirm btn'>Yes</button>
                        <button onClick={handleClick} className='modal-deny btn danger-btn'>Cancel</button>
                    </div>
                </div>
            </div>
        )
 }
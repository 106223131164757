import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import './Login.css'
import { useHistory } from "react-router-dom"
import AboutBottom from '../about/AboutBottom'

export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login, isPending, error} = useLogin()
    const history = useHistory()
    
    const handleSubmit = (e) => {
        e.preventDefault()
        login(email,password)
    }

    return (
        <div className='auth-container'>
            <form className='auth-form' onSubmit={handleSubmit}>
                <h2>Login </h2>
                <label>
                    <span>email:</span>
                    <input 
                    type="email"
                    required
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}
                    />
                </label>
                <label>
                    <span>password:</span>
                    <input 
                    type="password"
                    required
                    onChange={(e)=>setPassword(e.target.value)}
                    value={password}
                    />
                </label>
                {error && <div className='error'>Incorrect username or password...</div>}
                {!isPending && <button className="btn">Login</button>}
                {isPending && <button disabled className="btn">Loading</button>}
                {error && <p className='login-link forgot' onClick={() => { history.push('/reset') }}>Forgot your password?</p>}
                <p className='login-link' onClick={() => { history.push('/signup') }}>sign up with new account</p>
            </form>
            <AboutBottom/>
        </div>
    )
}
import './Dashboard.css'
import NotebookButtons from '../../components/NotebookButtons'
import PencilIcon from '../../assets/pencil_icon.svg'
import DeleteIcon from '../../assets/delete_icon.svg'
import { useCollection } from '../../hooks/useCollection'
import { useAuthContext } from "../../hooks/useAuthContext"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from 'react'
import { useUserUpdate } from '../../hooks/useUserUpdate'
import ReactTooltip from 'react-tooltip'
import PageNav from '../../components/Generic/PageNav'
import Modal  from '../../components/Generic/Modal'

export default function Dashboard() {
    const history = useHistory()
    const {user} = useAuthContext()
    const { savePreferences, userDoc } = useUserUpdate()
    const { documents, deleteDocument, orderBy, setOrderBy } = useCollection(`users/${user.uid}/notebooks`,null,['createdAt','desc'])
    const [modalFocus, setModalFocus] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [gridView, setGridView] = useState(userDoc?.settings?.gridView)
    let userName = user.displayName || user.email.split('@')[0]
    userName = userName.charAt(0).toUpperCase() + userName.slice(1)

    const handleSort = () => {
        setOrderBy((prev)=>{
            if (prev[1]==='desc'){
                return ['createdAt','asc']
            }
            return ['createdAt','desc']
        })
    }
    useEffect(()=>{
        setGridView(userDoc?.settings?.gridView)
    },[userDoc])

    const handleClick = (id) => {
        history.push(`/NoteBook/${id}`)
    }
    const handleDelete = (id)=>{
        deleteDocument(id)
    }
    const handleView = ()=>{
        savePreferences(!gridView)
        // setGridView(prev=>!prev)
    }

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[gridView, orderBy])

    return (
        <div className='dashboard'>
            <ReactTooltip backgroundColor='white' className='opaque' textColor='grey' place='bottom' effect='solid'/>
            {/* <div className='button-tray'>
                <div className='tray-icons'>
                    <div className='add tray-icon' onClick={()=>{}}><img className='' src={AddIcon}></img></div>
                </div>
            </div> */}
            <NotebookButtons 
                className='top-buttons' 
                handleToggle={handleView} 
                display={
                    [documents?.length<31 && 'note',
                    orderBy[1]==='desc' && 'reverse',
                    orderBy[1]!=='desc' && 'forward',
                    'infoIcon',
                    !gridView && 'grid',
                    gridView && 'list']}
                handleSort={handleSort} 
                add={()=>{}}
            />
            <h2 className='notebook-title'>Saved Notebooks</h2>
            <div className='pageList'>
                <div className={gridView ? 'notebook-grid' : 'notebook-list'}>
                    {documents && documents.map((data)=>{
                        return (
                        <div className={gridView ? 'notebook-grid-item' : 'notebook-list-item'} key={data.id}>
                            <div className='btns'>
                                <img 
                                alt='delete'
                                src={DeleteIcon} 
                                onClick={(e)=>{
                                    setModalFocus({id:data.id,
                                                y:Math.max(e.clientY,150),
                                                header:'Delete Notebook',
                                                message:`Delete notebook "${data.title}"?`})
                                    setShowModal(true)
                                    }}>
                                </img>
                                <img alt='edit' src={PencilIcon} onClick={()=>{handleClick(data.id)}}></img>
                            </div>
                            <h4 className='page-title'>{data.title.slice(0,19)}{data.title.length>19?'..':''}</h4>
                            <div className='gridCell'>
                                <h6 className='gridItem gridDesc'>{`${data.description || '...'}`}</h6>
                            </div>
                            <div className='gridCell'>
                                <h5 className='author gridItem'>{userName}</h5>
                            </div>
                            <div className='gridCell'>
                                <span className='date gridItem'>{data.createdAt.toDate().toISOString()}</span>
                            </div>
                            {/* <p>{`"${data.description || ''}"`}</p> */}
                        </div>)
                    })}
                </div>
            </div>
            <PageNav page={0}/>
            {showModal && <Modal 
                            id={modalFocus.id}
                            height={modalFocus.y}
                            header={modalFocus.header}
                            message={modalFocus.message}
                            handleModalAffirm={handleDelete}
                            setShowModal={setShowModal}/>}
        </div>
    )
}
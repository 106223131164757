import { NavLink } from 'react-router-dom'
import './SideBar.css'
import Logout from '../../assets/logout.svg'
import NewIcon from '../../assets/sticky_note.svg'
import DocsIcon from '../../assets/documents_icon.svg'
import CogIcon from '../../assets/cog_icon.svg'
import KeyIcon from '../../assets/key_icon.svg'
import InfoIcon from '../../assets/info_icon.svg'
import DoubleArrow from '../../assets/double_arrow.svg'
import Group from '../../assets/group.svg'
import { useLogout } from '../../hooks/useLogout'
import { useState } from 'react'

export default function SideBar() {
    const {logout} = useLogout()
    const [show] = useState(true)
    const [slim, setSlim] = useState(true)

    // let displayName = user.email.split('@')[0]
    // displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1)
    return (
        <>
        <div className ={slim ? 'sidebar slim-sidebar' : 'sidebar thick-sidebar'} id={show ? "slide" : "slideout"}>
            <div className='sidebar-content'>
                {/* <div className='user'>
                    <button className='profile-icon'><h1><strong>#</strong></h1></button>
                    <p>{displayName}</p>
                </div> */}
                {/* <hr/> */}
                <nav className='links'>
                    <ul>
                        <li>
                            <NavLink to='/newnotebook'>
                                <img src={NewIcon} alt="AddIcon" />
                                <span>New notebook</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to='/'>
                                <img src={DocsIcon} alt="AddIcon" />
                                <span>My notes</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to='/groups/all'>
                                <img src={Group} alt="GroupIcon" />
                                <span>Groups</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/api'>
                                <img src={KeyIcon} alt="AddIcon" />
                                <span>API key</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/info'>
                                <img src={InfoIcon} alt="AddIcon" />
                                <span>Info</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/settings'>
                                <img src={CogIcon} alt="SettingsIcon" />
                                <span>Settings</span>
                            </NavLink>
                        </li>
                        {/* <hr/> */}
                        <li className='logout-btn' onClick={logout}>
                            <NavLink exact to='/login'>
                                <img src={Logout} alt="LogoutIcon" />
                                <span>Logout</span>
                            </NavLink>
                        </li>
                        <li className='collapse' onClick={()=>{setSlim(prev=>!prev)}}>
                            <div>
                                <img src={DoubleArrow} alt="collapse" />
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        {!show && (
            <ul>
                <li className='expand' onClick={()=>{setSlim(prev=>!prev)}}>
                    <div>
                        <img src={DoubleArrow} alt="expand" />
                    </div>
                </li>
            </ul>
        )}
        </>
    )
}
import { useEffect, useState } from "react"
import { removeStopwords } from 'stopword'
import { MinHeap } from "../util/heap"

const algoliasearch = require('algoliasearch')

const client = algoliasearch(
    'UALDXGAZ36',
    '16e2c1bf89b774a7815ec582864c0f93' // search only API key, not admin API key
  )

const filterResults = (hits, search) => {
    const hitHeap = new MinHeap()
    // return hits.map((hit, i)=>
    for (const [ii, hit] of hits.entries()){
        let highlightWords = hit._highlightResult.content.value.match(/<em>(.*?)<\/em>/g)?.map(function(val){
            return val.replace(/<\/?em>/g,'') || []
         });
        const nonStopWords = [...removeStopwords(highlightWords),search]
        highlightWords = highlightWords.filter((w, index) => {
            return highlightWords.indexOf(w) === index;
        });
        const results = {tag:hit.path,content:{},highlight: nonStopWords.length >0 ? nonStopWords : highlightWords}
        let resultScore = 0
        const heap = new MinHeap()
        for (const [i,v] of Object.keys(hit.verses).entries()) {
            let score = 0
            for (const word of [...highlightWords, ...nonStopWords] || []) {
                if (hit.verses[v].toLowerCase().includes(word.toLowerCase())){
                    score += 1
                    resultScore += 1
                }
            }
            if (i===5){
                heap.buildHeap()
            }
            if (i<5){
                heap.values.push({score, num:v, verse: hit.verses[v]})
            } else if (score > heap.peek().score){
                heap.extractMin()
                heap.add({score, num:v, verse: hit.verses[v]})
            }
        }
        for (let i = 0 ; i<5; i+=1) {
            const data  = heap.extractMin()
            if (data) {
                results.content[data.num]=data.verse
            }
        }
        results.score = resultScore
        if (ii===10){
            hitHeap.buildHeap()
        }
        if (ii<10){
            hitHeap.values.push(results)
        } else if (results.score > hitHeap.peek().score) {
            hitHeap.extractMin()
            hitHeap.add(results)
        }
    }
    const returnValue = []
    let v = hitHeap.extractMin()
    for (let i = hitHeap.values.length; i>=0 ; i-=1) {
        returnValue.push(v)
        v = hitHeap.extractMin()
    }
    return returnValue.reverse()
}

export const useQuery = (_index=null) => {
    const [search, setSearch] = useState(null)
    const [queryResults, setQueryResults] = useState(null)
    const index = client.initIndex(_index || "kjv")

    useEffect(()=>{
        const runSearch = async () =>{
            if (search){
                const results = await index.search(search)
                const filtered = filterResults(results.hits, search)
                // console.log(filtered)
                setQueryResults(filtered)
                // console.log(results)
            }
        }
        runSearch()
    },[search])

    const query = (_search) => {
        if (_search){
            setSearch((prev)=>{
                if (prev===_search){
                    if (_search.charAt(_search.length -1)===' '){
                        return _search.slice(0,-1)
                    }
                    return _search+' '
                }
                return _search
            })
        } else {
            setSearch(null)
            setQueryResults(null)
        }
    }
    const popResults =(index) => {
        setQueryResults(queryResults.filter((x,i)=>i!==index))
    }
    return { query, queryResults, popResults}
}
import './About.css'
import AboutBottom from './AboutBottom'
import { useHistory } from 'react-router-dom'

export default function About() {
    const history = useHistory()
    return (
        <div className='about'>
            {/* <Terminal blockMaps={[{tag:' ',identity:"Gospel as data . . . ",content:''}]}/> */}
            <div className='banner'>
                <h1 className='banner-header'>GospelStack</h1>
                <div className='info'>
                    <h1>{'< API / Query / Notebooks >'}</h1>
                    <h4>version: alpha</h4>
                </div>
            </div>
            <div className='marketing'>
                <div className='horizontal-mark'>
                    <div className='mark-card'>
                        <p>❤️ ✨</p>
                        <em>Create notebooks to organize and save your favorite passages!</em>
                        <div className='mark-btn btn' onClick={()=>{history.push('newnotebook')}}>Create</div>
                    </div>
                    <div className='mark-card'>
                        <p>🤩 ⚡️</p>
                        <em>Join groups to share and collaborate!</em>
                        <div className='mark-btn btn' onClick={()=>{history.push('groups/all')}}>Collaborate</div>
                    </div>
                    <div className='mark-card'>
                        <p>🥳 🔥</p>
                        <em>Develop your own applications using our API!</em>
                        <div className='mark-btn btn' onClick={()=>{history.push('api')}}>Develop</div>
                    </div>

                </div>
            </div>
            <AboutBottom/>
        </div>
    )
}
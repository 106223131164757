import './Alert.css'
import Icon from '../../assets/delete_icon.svg'
import { useState, useEffect } from 'react'

 export default function Alert({type,message, clearMessage}) {
    const [visible, setVisible] = useState(false)
    useEffect(() => {
      if(!message){
       setVisible(false)
       return
      }
      setVisible(true)
      const timer = setTimeout(() => {
        setVisible(false)
        clearMessage()
      }, 5000)
      return () => clearTimeout(timer)
    }, [message])

    return <div className={`${visible ? 'alert' : 'invisible-alert'} ${type}`}>
                {/* <img></img> */}
                <div className='alert-tag'></div>
                <p>{message}</p>
                <span className="material-symbols-outlined alert-icon">info</span>
            </div>
 }
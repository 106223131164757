import { useEffect, useState } from "react"
import { firestore } from "../firebase/config"
import {useHistory} from 'react-router-dom'

export const useDocument = (collection, id) => {
  // const history = useHistory()
  const [document, setDocument] = useState(null)
  const [ref, setRef] = useState(null)
  const [error, setError] =  useState(null)
  const [docId, setDocId] = useState(null)

  useEffect(()=>{
    setDocId(id)
  },[id])

  useEffect(()=>{
    if (docId && !['---','new'].includes(docId)){
      const ref = firestore.collection(collection).doc(docId)
      setRef(ref)

      const unsubscribe = ref.onSnapshot((snapshot)=>{
        setDocument({...snapshot.data(),docId:snapshot.id,exists:snapshot.exists})
        setError(null)
      }, (error) => {
        setError('Unable to read document...')
      })
  
      return () => {
        unsubscribe()
      } 
    }
  },[collection, docId])

  const updateDocument = async (data) =>{
    if (ref) {
      ref.update(data)
    }
  }
  const addDocument = async (data, id) =>{
    if (!ref) {

      const snap = id ? await firestore.doc(`${collection}/${id}`).set(data) : await firestore.collection(collection).add(data)
      setDocId(snap.id)
    }
  }

  const saveDocument =  async (data,id) =>{
    if (ref) {
      ref.update(data)
    } else {
      data.createdAt = new Date()
      const snap = id ? await firestore.doc(`${collection}/${id}`).set(data) : await firestore.collection(collection).add(data)
      setDocId(snap.id)
    }
  }

  const deleteDocument = async () =>{
    if (ref) {
      // history.push('/')
      await ref.delete()
    }
  }
  return { document, error, saveDocument, deleteDocument }
}
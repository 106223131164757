const versions = ['KJV','ESV']
const books = ['Matthew','Mark','Luke','John']

const isNull = (value) => {
    return !value || value.length === 0 || value === '---'
}
const cursorToSearch = (cursor) => {
    let search = cursor.bible
    if (cursor.book){
        search+=`/${cursor.book}`
    }
    if (cursor.chapter){
        search+=`/${cursor.chapter}`
    }
    if (cursor.verse) {
        search+=`/${cursor.verse}`
    }
    return search
}

const nameConvention = (text, bookIds) => {
    if (text.split(' ').length===2){
        const digits = text.split(' ')
        return digits[0] + ' ' + digits[1].charAt(0).toUpperCase() + digits[1].slice(1).toLowerCase()
    }
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

const parseSearch = (text, bookIds = {}, defaultVersion=null) => {
    if (!text.includes('/')){
        return text
    }
    const digits = text.split('/').filter((d)=>(d.toLowerCase()!=='bible'))

    const bookNamesToIds = {}
    Object.keys(bookIds).map((key)=>{
        bookNamesToIds[bookIds[key]] = key
        return null
    })

    const preCursor = {
        bible: defaultVersion || 'KJV',
        book: '---',
        chapter: '-1',
        verse: null
    }

    const bookIndex = digits.findIndex((digit)=>(
        Object.keys(bookIds).map(b=>b.toLowerCase()).includes(digit.toLowerCase())||Object.keys(bookNamesToIds).map(b=>b.toLowerCase()).includes(digit.toLowerCase())
        ))
    if (digits.length === 2){
        // bookname/chapter or version/booknam
        if (bookIndex === 0){
            const bookId = bookNamesToIds[nameConvention(digits[0].trim())] || digits[0].toUpperCase().trim()
            return {
                bible: defaultVersion || 'KJV',
                book: bookId,
                chapter: digits[1].includes('.') ? digits[1].trim().toUpperCase() : `${bookId}.${digits[1]}`,
                verse: null
            }
        } else if (bookIndex === 1){
            return {
                bible: digits[0].trim().toUpperCase(),
                book: bookNamesToIds[nameConvention(digits[1].trim())] || digits[1].toUpperCase().trim(),
                chapter: '-1',
                verse: null
            }
        }
        return preCursor
    } else if (digits.length === 3){
        // bookname/chapter/verse or version/bookname/chapter
        if (bookIndex === 0){
            const bookId = bookNamesToIds[nameConvention(digits[0].trim())] || digits[0].toUpperCase().trim()
            return {
                bible: defaultVersion || 'KJV',
                book: bookId,
                chapter: digits[1].includes('.') ? digits[1].trim().toUpperCase() : `${bookId}.${digits[1]}`,
                verse: digits[2].trim()
            }
        } else if (bookIndex === 1){
            const bookId = bookNamesToIds[nameConvention(digits[1].trim())] || digits[1].toUpperCase().trim()
            return {
                bible: digits[0].trim().toUpperCase(),
                book: bookId,
                chapter: digits[2].includes('.') ? digits[2].trim().toUpperCase() : `${bookId}.${digits[2]}`,
                verse: null
            }
        }
        return preCursor
    }
    // 4+ digits...
    digits.forEach((digit,index)=>{
        switch (index) {
            case 0:
                preCursor.bible = digit.trim().toUpperCase()
                break
            case 1:
                preCursor.book = bookNamesToIds[nameConvention(digit.trim())] || digit.toUpperCase().trim()
                break
            case 2:
                preCursor.chapter = digit.includes('.') ? digit.trim().toUpperCase() : `${preCursor.book.trim()}.${digit}`
                break
            case 3:
                preCursor.verse = digit.trim()
                break
                // preCursor.verse = preCursor.includes(':') ? digit.split(':')[0].trim() : digit.trim()
            default:
                //
        }
    })
    return preCursor
}

const highlight = (text, match=null) => {
    const blocks = []
    let words = []
    text.split(' ').map((word)=>{
        if ((match && match.some((m)=>(word.includes(m)))) || (!match && (word.length>4 && word.slice(0,2)==='**' && word.slice(word.length-2,word.length) ==='**'))){
            if (words.length>0){
                blocks.push({content:words.join(' '),class:''})
                words = []
            }
            blocks.push({content: word.replaceAll('**','').replaceAll('*',' '),class:'highlighted'})
        } else {
            words.push(word)
        }
        return null
    })
    if (words.length>0){
        blocks.push({content:words.join(' '),class:''})
        words = []
    }
    return blocks
}

// match [{char:'A',class:'red'},...]
const multiCharHighlight = (text, match=[]) => {
    const blocks = []
    let chars = []
    text.split('').map((char)=>{
        for (const [index, matchItem] of match.entries()) {
            if (char === matchItem.char){
                blocks.push({content:chars.join(''),class:''})
                chars = []
                blocks.push({content: char,class: matchItem.class})
                break
            } else if (index === match.length -1) {
                chars.push(char)
            }  
        }
        return null
    })
    if (chars.length>0){
        blocks.push({content:chars.join(''),class:''})
        chars = []
    }
    return blocks
}

export {versions, books, isNull, parseSearch, cursorToSearch, highlight, multiCharHighlight}
import Cell from "./Cell"
import Modal from "./Generic/Modal"
import NotebookButtons from "./NotebookButtons"
import { useState, useEffect } from "react"
import './Notebook.css'
import {nanoid} from 'nanoid'
import {useDocument} from '../hooks/useDocument'
import { useAuthContext } from "../hooks/useAuthContext"
import { Redirect} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

const moveUp = (index, arr) => {
    const ids = arr.map((obj)=>{return obj.props.cellId})
    if (index < arr.length - 1  && arr.length>1) {
        [ids[index], ids[index+1]] = [ids[index+1], ids[index]]
        return (ids.map((id)=>arr.find((o)=>o.props.cellId===id)))
    }
    return arr
  }
const moveDown = (index, arr) => {
    const ids = arr.map((obj)=>{return obj.props.cellId})
    if (index > 0  && arr.length>1) {
        [ids[index], ids[index-1]] = [ids[index-1], ids[index]]
        return (ids.map((id)=>arr.find((o)=>o.props.cellId===id)))
    }
    return arr
  }

export default function Notebook({doc}) {
    const {user} = useAuthContext()
    const { document, saveDocument, deleteDocument } = useDocument(`users/${user.uid}/notebooks`,doc)
    const [modalFocus] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [preview, setPreview] = useState(false)
    const [desc, setDesc] = useState('')

    const removeCell = (id) => {
        setCells(prev=>prev.filter((cell)=>cell.props.cellId!==id))
    }
    const addCell = () => {setCells(prev=>[...prev, <Cell 
                                                    handleCache={handleCache} 
                                                    reorder={setCellOrder} 
                                                    remove={removeCell} 
                                                    cellId={nanoid()} 
                                                    initClear={true}
                                                    initSearch={'KJV/JHN/JHN.1/1:5'}
                                                    key={nanoid()}/>])}
    const [cells, setCells] = useState([])
    const [cellOrder,setCellOrder]=useState([])
    const [title, setTitle] = useState(document ? document.title : '')
    const [cachedSave, setCachedSave ] = useState({}) // this probably doesn't need to be state
    
    useEffect(()=>{
      setPreview(document?.preview)
    },[document])
    const handleCache = (id, data) => {
      setCachedSave((prev) => {
        prev[id] = data
        return prev
      })
    }
    const handleSave = () => {
        saveDocument({title:title.length>0 ? title : 'Untitled' ,preview:preview||false ,notebookCells:cells.map((cell)=>{
            return {id:cell.props.cellId,
                    description:'Sample description',
                    search:cachedSave[cell.props.cellId].search,
                    note:cachedSave[cell.props.cellId].note,
                    clear:cachedSave[cell.props.cellId].clear
                  }
          })
        })
    }
    
    useEffect(()=>{
      if (cellOrder.length>0){
        reorderCell(cellOrder[0],cellOrder[1])
      }
    },[cellOrder])

    const reorderCell = (id,dir) => {
      const index = cells.findIndex(cell => cell.props.cellId === id)
      if (dir==='down'){
          setCells((prev)=>{return [...moveUp(index,prev)]})
      }else if (dir==='up'){
          setCells((prev)=>{return [...moveDown(index,prev)]})
      }
  }
    useEffect(()=>{
      if (!document && doc === 'new'){
        setCells([<Cell 
          handleCache={handleCache} 
          reorder={setCellOrder} 
          remove={removeCell} 
          initSearch={'KJV/JHN/JHN.1/1:5'}
          cellId={nanoid()} 
          initClear={true}
          key={nanoid()}/>])
      } else if (document && document.exists) {
        setCells(document.notebookCells.map((cell)=>{
          return <Cell 
            handleCache={handleCache} 
            reorder={setCellOrder} 
            remove={removeCell} 
            cellId={cell.id} 
            initNote={cell.note}
            initClear={cell.clear}
            initSearch={cell.search}
            key={nanoid()}/>
        }))
        setTitle(document.title)
      }
    },[document])

    const handleDelete = () => {
      deleteDocument()
      setShowModal(false)
    }

    if (doc==='new' && document) {
      return <Redirect to={`/notebook/${document.docId}`} />
    } else if (document && !document.exists) {
      return <Redirect to={'/'} />
    }
    const buttonDisplay = cells.length > 49 ? ['save', 'all'] : ['add','save', 'all']
    if (document){
      buttonDisplay.push('delete')
    }
    if (preview){
      buttonDisplay.push('noprev')
    } else {
      buttonDisplay.push('preview')
    }

    const handleToggle = () => {
      setPreview(prev=>!prev)
    }
    return (
            <div className={'notebook' + (preview ? ' preview' : '')}>
                <ReactTooltip className='opaque' backgroundColor='#f1f1f1' textColor='grey' place='bottom' effect='solid'/>
                <NotebookButtons handleToggle={handleToggle} className='top-buttons' display={buttonDisplay} handleDelete={()=>{setShowModal(true)}} save= {handleSave} add={addCell}/>
                <h2 className='notebook-title' >
                  <input 
                    className="notebook-title-input" 
                    placeholder="My Notebook" 
                    type="text" 
                    value={title}
                    onChange={(e)=>setTitle(e.target.value)}></input>
                </h2>
                {/* <div className="noteDesc">
                  <Note note={desc} setNote={setDesc}/>
                </div> */}
                <div className="pageList">
                  {cells.map((cell)=>{
                      return cell
                  })}
                </div>

                {cells.length > 0 && (
                  <div className="bottomNav">
                  <NotebookButtons 
                  display={buttonDisplay} 
                  handleToggle={handleToggle} 
                  handleDelete={()=>{setShowModal(true)}} 
                  save={handleSave} 
                  add={addCell}/>
                  </div>
                )}
            {
                showModal && <Modal id={modalFocus.id}
                            header='Delete'
                            height={150}
                            message="Delete this notebook?"
                            handleModalAffirm={handleDelete}
                            setShowModal={setShowModal}/>
            }
            </div>
    )
}

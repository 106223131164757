import { useEffect, useState } from 'react'
import { useSignup } from '../../hooks/useSignup'
import { useHistory } from "react-router-dom"
import AboutBottom from '../about/AboutBottom'

import './Signup.css'

export default function Signup() {

    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [password, setPassword] = useState('')
    // const [confirmPassword, setConfirmPassword] = useState('')
    const [displayName] = useState('')
    const [thumbnail] = useState(null)
    const [error,setError] = useState(null)
    const { signup, error: signupError, isPending } = useSignup()

    useEffect(()=>{
        setError(signupError)
    },[signupError])
    const history = useHistory()

    // if (signupError) {
    //     setError(signupError)
    // }
    const handleSubmit = (e) => {
        e.preventDefault()
        setError(null)
        if (email !== confirmEmail) {
            setError('Emails do not match...')
        } else {
            signup(email, password, displayName, thumbnail)
        }

    }

    // const handleFileChange = (e) => {
    //     setThumbnail(null)
    //     setError(null)
    //     let selected = e.target.files[0]

    //     if (!selected){
    //         setError('No file found...')
    //         return
    //     } else if (!selected.type.includes('image')){
    //         setError('Must select file type image...')
    //         return
    //     }  else if (selected.size > 100000) {
    //         setError('File size must be less than 100kb...')
    //         return
    //     }
    //     setThumbnail(selected)
    // }

    return (
        <div className='auth-container'>
            <form className='auth-form' onSubmit={handleSubmit}>
                <h2>Sign Up</h2>
                <label>
                    <span>email:</span>
                    <input 
                    type="email"
                    required
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}
                    />
                </label>
                <label>
                    <span>confirm email:</span>
                    <input 
                    type="email"
                    required
                    onChange={(e)=>setConfirmEmail(e.target.value)}
                    value={confirmEmail}
                    />
                </label>
                <label>
                    <span>password:</span>
                    <input 
                    type="password"
                    required
                    onChange={(e)=>setPassword(e.target.value)}
                    value={password}
                    />
                </label>
                {/* <label>
                    <span>confirm password:</span>
                    <input 
                    type="password"
                    required
                    onChange={(e)=>setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    />
                </label> */}
                {/* <label>
                    <span>display name:</span>
                    <input 
                    type="text"
                    required
                    onChange={(e)=>setDisplayName(e.target.value)}
                    value={displayName}
                    />
                </label> */}
                {/* <label>
                    <span>profile thumbnail:</span>
                    <input 
                    type="file"
                    onChange={handleFileChange}
                    />
                </label> */}
                {error && <div className='error'>{error}</div>}
                {!isPending && <button className="btn">Sign Up</button>}
                {isPending && <button disabled className="btn">Loading</button>}
                <p className='login-link' onClick={() => { history.push('/login') }}>login existing account</p>
            </form>
            <AboutBottom/>
        </div>
    )
}
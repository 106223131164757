import './Terminal.css'
import { highlight } from '../../util/util'
import Expand from '../../assets/expand.svg'
import Minus from '../../assets/minus_icon.svg'
import Heart from '../../assets/heart.svg'
import { nanoid } from 'nanoid'
import Alert from '../../components/Generic/Alert'
import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import DropModal from '../Generic/DropModal'

export default function Terminal({listFormat, route, blockMaps, handleClick, clearTerm, color={Jesus:'purple'}, highlighted=null, queryTerm=false, identity='gospel-stack@'}) {
    // const color = color
    const [alertMessage, setAlertMessage] = useState(null)
    const [showDropModal, setShowDropModal] = useState(false)
    const {user} = useAuthContext()
    const expandedColors = {}
    color && Object.keys(color).map((key)=>{
        expandedColors[key+'.']=color[key]
        expandedColors[key+',']=color[key]
        expandedColors[key+'"']=color[key]
        expandedColors['"'+key]=color[key]
        expandedColors['-'+key]=color[key]
        expandedColors[key.toLowerCase()]=color[key]
        expandedColors[key.toUpperCase()]=color[key]
        expandedColors[key.charAt(0).toUpperCase() + key.slice(1)]=color[key]
        return null
    })

    const handleHeart = () => {
        if (!user){
            setAlertMessage('You must be logged in to save to notebook')
        } else {
            setShowDropModal(true)
        }
    }
    return (
        <div className={queryTerm ? 'query-terminal' : 'terminal'}>
        <Alert message={alertMessage} type='warning' clearMessage={()=>{setAlertMessage(null)}}/>
        {user && showDropModal && (
                <DropModal 
                    id={nanoid} 
                    height={105} 
                    header='Save to notebook' 
                    setShowModal={setShowDropModal} 
                    collection={`users/${user?.uid}/notebooks`}
                    value={
                        {description:'',id:nanoid(), note:'', search:route}
                    }
                />
            )}
        {blockMaps.map((map,index)=>{
            return (
                <div key={nanoid()}>
                <div className='term-header'>
                {queryTerm && <h3><span>{map.tag}</span></h3>}
                {!queryTerm && <h3><span className='blue identity'>{map.identity || identity}</span> <span>{map.tag}</span> <span className='green division'>{'%'}</span> </h3>}
                <div className='term-nav'>
                    <div data-tip="save" data-place='top' className='term-btn heart' onClick={handleHeart}><img src={Heart} alt='*'></img></div>
                    <div data-tip="clear" data-place='top' className='term-btn minus' onClick={clearTerm}><img src={Minus} alt='-'></img></div>
                    <div data-tip="expand" data-place='top' className='term-btn exp' onClick={handleClick}><img src={Expand} alt='+'></img></div>
                </div>
                </div>
                {/* <hr className='divider'/> */}
                {listFormat && (
                    <>
                    {map.content && (
                        <ul className='block'>
                                {Object.keys(map.content).map((key) => (
                                        <li key={key}>
                                        <span className='verse-number red'>{key}</span>
                                        {!color && highlighted && (
                                            highlight(map.content[key],highlighted).map((chunk)=>{
                                                return chunk.class === 'highlighted' 
                                                ? <span key={nanoid()}>{' '}<span className='matchWord'>
                                                    {chunk.content.replace('¶','')}</span>{' '}</span> 
                                                : <span key={nanoid()} className={chunk.class}>{chunk.content.replace('¶','')}</span>
                                            })
                                        )}
                                        {color && (
                                            map.content[key].split(' ').map((word,index)=>{
                                                return expandedColors[word] 
                                                    ? <span className={expandedColors[word]} key={nanoid()}> {word.replace('¶','')} </span> 
                                                    : <span key={nanoid()}> {word.replace('¶','')} </span>
                                            })
                                        )}
                                        </li>
                                ))}
                        </ul>
                    )}
                    </>
                )}
                {!listFormat && (
                    <>
                    {map.content && (
                        <p className='par-block'>
                                {Object.keys(map.content).map((key,index) => (
                                        <span key={key}>
                                        {index!==0 && map.content[key].includes('¶') && <><br></br><br></br><span className='indent'>{'    '}</span></>}
                                        {index===0 && map.content[key].includes('¶') && <span className='indent'>{'    '}</span>}
                                        <span className='verse-number red'>{key}</span>
                                        {!color && highlighted && (
                                            highlight(map.content[key],highlighted).map((chunk)=>{
                                                return chunk.class === 'highlighted' 
                                                ? <span key={nanoid()}>{' '}<span className='matchWord'>
                                                    {chunk.content.replace('¶','')}</span>{' '}
                                                </span> 
                                                : <span key={nanoid()} className={chunk.class}>{chunk.content.replace('¶','')}</span>
                                            })
                                        )}
                                        {color && (
                                            map.content[key].split(' ').map((word,index)=>{
                                                if (word.includes('¶')){
                                                    return expandedColors[word] 
                                                    ? <span className={expandedColors[word]} key={nanoid()}> {word.replace('¶','')} </span> 
                                                    : <span key={nanoid()}> {word.replace('¶','')} </span>
                                                }
                                                return expandedColors[word] 
                                                    ? <span className={expandedColors[word]} key={nanoid()}> {word.replace('¶','')} </span> 
                                                    : <span key={nanoid()}> {word.replace('¶','')} </span>
                                            })
                                        )}
                                        </span>
                                ))}
                        </p>
                    )}
                    </>
                )}
                </div>
            )
        })}

    </div>
    )
}
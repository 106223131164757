import DeleteIcon from '../../assets/delete_icon.svg'
import SearchIcon from '../../assets/search.svg'

 export default function SearchBar({search, setSearch}) {
    const clearSearch = (e) =>{
        e.preventDefault()
        setSearch('')
    }
    return (
        <div className='searchBar'>
        <button type='submit' className='searchIcon'><img src={SearchIcon} alt='?'></img></button>
        <input 
        className='searchInput'
        type="text"
        onChange={(e)=>setSearch(e.target.value)}
        value={search}
        placeholder='kjv/jhn/1/1:5'
        spellCheck="false"
        />
        <button onClick={clearSearch} className='searchIcon searchClear'><img src={DeleteIcon} alt='x'></img></button>
    </div>
    )
 }
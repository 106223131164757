import './Info.css'
import {nanoid} from 'nanoid'
import { highlight, multiCharHighlight } from '../../util/util'

const infoLi = (title, explanations, examples) => {
    const exampleMatch = [{char:'[',class:'purple bold'},{char:']',class:'purple bold'}, {char:'&',class:'and bold'}, {char:'|',class:'and bold'}]
    return (
        <li>
            <h4 className='info-item-title'>{highlight(title,['(this*feature*is*pending)']).map((chunk)=>{
                return chunk.class === 'highlighted' 
                ? <span key={nanoid()}>{' '}<span className='orange'>{chunk.content}</span>{' '}</span> 
                : <span key={nanoid()} className={chunk.class}>{chunk.content}</span>
            })}</h4>
            {explanations && <div className='info-item-expl'>
                {explanations.map((exp)=><p key={nanoid()}>{
                    highlight(exp).map((chunk)=>{
                        return chunk.class === 'highlighted' 
                        ? <span key={nanoid()}>{' '}<span className={chunk.class}>{chunk.content}</span>{' '}</span> 
                        : <span key={nanoid()} className={chunk.class}>{chunk.content}</span>
                    })
                }</p>)}
            </div>}
            {examples && <>
                <p className='exmpl'>example:</p>
                <div className='info-item-example'>
                {examples.map((example)=> <p key={nanoid()}>
                        {multiCharHighlight(example, exampleMatch).map((chunk)=>{
                            return <span key={nanoid()} className={chunk.class}>{chunk.content}</span>
                        })}
                    </p>)}
            </div>
            </>}
        </li>
    )
}
export default function Info() {
    return (
        <div className='info-page'>
            <div className='info-content'>
                <h2 className='notebook-title'>Info</h2>
                <p className='description'><em>This is the info page! The latest supported features are documented here.</em></p>
                <h4 className=''>Search Bar</h4>
                <p className='top-desc'>
                    The search bar is a query tool available in notebook cells as well as the "browse" page search form. 
                    The following search methods are supported:
                </p>
                <ul>
                    {
                        infoLi(
                            'Fast path',
                            ['- You can query a specific chapter, verse, or verse range directly by querying the explicit route.',
                            '- The route path should be formated generally like this:','**bible/[version]/[book]/[chapter]/[verse]:[verse]**',
                            "- Capitalization doesn't matter.","- Feel free to reference proper names (John) or IDs (JHN).",
                            "- Verse start/stop parameters are not required.",
                            '- Use the "Browse" page to explore which resources are available.'],
                            ['bible/John/1/1','kjv/John/1','John/1/1','kjv/John/1/1:3', 'jhn/1']
                        )
                    } 
                    {
                        infoLi(
                            'Key words',
                            ["Key words/phrases are submitted to the query engine for content match. You will have access to the **top*matches.** Select/expand a match to set its path as the active cursor."],
                            ['"In the beginning"', 'word']
                        )
                    }
                    {
                        infoLi(
                            'Boolean operators (this*feature*is*pending)',
                            ['Boolean operators can be applied to **logically*combine*or*exclude** results. The following operators are supported:',
                            '1) Logical **AND** is represented by && (exclusive).','2) Logical **OR** is represented by || (inclusive).',
                            'Logical operators can be applied to key words, fast paths, and/or digits within fast paths.'],
                            ['kjv/John/1 && "In the beginning"','kjv/John/1 || kjv/John/2']
                        )
                    }
                    {
                        infoLi(
                            'Brackets (this*feature*is*pending)',
                            ['Brackets are used with boolean operators to control the **evaluation*order** of the expression. In the bellow examples...',
                            '- The first produces an aggregation of verses 1 through 3 in chapter 1 of both books Mark and John.',
                            '- The second produces an aggregation of both verse one and three in chapter one of both books Matthew and John.',
                            '- The third produces verses in John chapter one that both contain the phrase "In the beginning" and also contain the term "word".',
                            '- The fourth example produces all verses that identically appear in both the books of John and Matthew.'],
                            ['kjv/[John || Mark]/1/1:3',
                            'kjv/[John || Matthew]/1/[1 || 3]',
                            'kjv/John/1 && ["In the beginning" && word]',
                            'kjv/[John && Matthew]/1']
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
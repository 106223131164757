import { useEffect, useState, createContext} from 'react'
import { useDocument } from '../hooks/useDocument'
import { useBook } from '../hooks/useBook'
import { parseSearch, isNull, versions } from '../util/util'
import ReactTooltip from 'react-tooltip'
import { useQuery } from '../hooks/useQuery'
import { useUserUpdate }from '../hooks/useUserUpdate'

export const BrowseContext = createContext()

export const BrowseContextProvider = ({ children }) => {
    const {userDoc } = useUserUpdate()
    // temp attributes filled out in form
    const [search, setSearch] = useState('')
    // const [activeFilter, setActiveFIlter] = useState(null)
    const [version, setVersion] = useState(userDoc?.settings?.defaultVersion || 'KJV')
    const [book, setBook] = useState('JHN')
    const [chapter, setChapter] = useState('JHN.1')
    const [verse, setVerse] = useState('')
    const [verseTo, setVerseTo] = useState('')
    const [listFormat, setListFormat] = useState(false)

    // real time vars for populating the form options
    const {document: bibleDoc} = useDocument('bibles',version)
    const {document: bookDoc} = useDocument('bibles/KJV/books',book)
    const {document: chapterDoc} = useDocument(`bibles/KJV/books/${book}/chapters`,chapter)

    // return data from the book
    const { chapterDocData, handlePageTurn, handleCursor, 
        expandCursor, cursorPointer, clearData} = useBook({'bible':userDoc?.settings?.defaultVersion || 'KJV','book':'JHN','chapter':'JHN1'})
    
    const {query, queryResults, popResults} = useQuery()

    let route = `bible/${cursorPointer.bible}/${cursorPointer.book}/${cursorPointer.chapter.split('.')[1]}` 
    route += cursorPointer.verse ? `/${cursorPointer.verse}` : ''
    const color = {Jesus:'gold',God:'gold',Lord:'gold',Yahweh:'gold',Christ:'gold'}

    useEffect(()=>{
        ReactTooltip.rebuild()
    },[chapterDocData])
    useEffect(()=>{
        if (userDoc?.settings?.defaultVersion && userDoc?.settings?.defaultVersion !== version){
            setVersion(userDoc.settings.defaultVersion)
            handleCursor({bible:userDoc.settings.defaultVersion,book,chapter,verse})
        }
    },[userDoc])

    const updateForm = (newCursor) => {
        if (newCursor.bible){
            setVersion(newCursor.bible.toUpperCase())
        }
        if (newCursor.book){
            setBook(newCursor.book)
        }
        if (newCursor.chapter){
            setChapter(newCursor.chapter)
        }
        if (newCursor.verse){
            setVerse(newCursor.verse)
        }
        setVerseTo('')
    }
    const handleSubmit = (e) => {
        e && e.preventDefault()
        if (search && search.length !== 0 && search !== '---') {
            const parsed = parseSearch(search, bibleDoc.bookIdNames || {}, userDoc?.settings?.defaultVersion || 'KJV')
            if (typeof parsed === 'object') {
                const verseTo = parsed.verse?.includes(':') ? parsed.verse.split(':')[parsed.verse.split(':').length -1] : null
                parsed.verse = verseTo ? parsed.verse.split(':')[0] : parsed.verse
                handleCursor(parsed, verseTo, false)
                // setActiveFIlter(null)
                query(null)
                return
            } else {
                // setActiveFIlter(search)
                query(search)
            }
            // setSearch('')
        } else {
            query(null)
        }
        handleCursor({
                bible: version !== 'Select' ? version : null,
                book: book.length >0 && book !== 'Select' ? book : null,
                chapter: chapter.length >0 && chapter !== 'Select' ? chapter : null,
                verse: verse.length >0 && verse !== 'Select' ? verse : null,
            },
            verseTo.length >0 && verseTo !== 'Select' ? verseTo : null,
            false)
    }
    const handleButtonClick = async (e,value) => {
        e.preventDefault()
        let newCursor = null
        if (queryResults){
            query(null)
        }
        if (value === 'search') {
            handleSubmit()
        } else if (value === 'next'){
            newCursor = await handlePageTurn('increment')
        } else if (value === 'prev') {
            newCursor = await handlePageTurn('decrement')
        } else if (value === 'snip'){
        } else if (value === 'reset'){
            newCursor = await handleCursor(isNull(version) ? {} : {bible:version})
            // resetForm()
        } else {
            const rand = isNull(version) ? {} : {bible:version}
            newCursor = await handleCursor(rand,false,true)
        }
        if (newCursor){
            updateForm(newCursor)
        }
    }

    const handleExpand = (e) => {
        e.preventDefault()
        if (cursorPointer.verse){
            expandCursor(cursorPointer)
            if (verse !== ''){
                setVerse('')
            }
            if (verseTo !== ''){
                setVerseTo('')
            }
        }
    }

    const clearSearch = (e) =>{
        e.preventDefault()
        setSearch('')
    }

    const handleMakeSelection = (route) => {
        setSearch(route.toLowerCase())
        const parsed = parseSearch(route, bibleDoc.bookIdNames || {}, userDoc?.settings?.defaultVersion || 'KJV')
        if (typeof parsed === 'object') {
            const verseTo = parsed.verse?.includes(':') ? parsed.verse.split(':')[parsed.verse.split(':').length -1] : null
            parsed.verse = verseTo ? parsed.verse.split(':')[0] : parsed.verse
            handleCursor(parsed, verseTo, false)
            query(null)
        }
    }
    
    // filter content
    const versesFiltered = !cursorPointer.verse && chapterDocData.verses ? {...chapterDocData.verses} : {} 
    const versePointerFrom = cursorPointer.verse 
                            ? cursorPointer.verse.includes(':') 
                                ? cursorPointer.verse.split(':')[0] 
                                : cursorPointer.verse 
                            : null
    const versePointerTo = (cursorPointer.verse && cursorPointer.verse.includes(':')) 
                            ? cursorPointer.verse.split(':').slice(-1) 
                            : versePointerFrom
    let r = versePointerFrom && chapterDocData.verses ? Object.keys(chapterDocData.verses).map((key)=>{
        if (Number(key) >= versePointerFrom && Number(key) <= versePointerTo){
            versesFiltered[key]=chapterDocData.verses[key]
        }
        return null
    }) : null


    if ((!queryResults && (Object.keys(versesFiltered).length === 0)) || (queryResults && Object.keys(queryResults).length === 0)) {
        versesFiltered.z ='No results found...'
    }

    const value ={
      handleSubmit,
      setVersion,
      version,
      bibleDoc,
      setBook,
      book,
      bookDoc,
      setChapter,
      chapter,
      chapterDoc,
      setVerse,
      verse,
      versions,
      setVerseTo,
      verseTo,
      setSearch,
      search,
      clearSearch,
      listFormat,
      queryResults,
      setListFormat,
      handleButtonClick,
      route,
      query,
      handleMakeSelection,
      popResults,
      chapterDocData,
      versesFiltered,
      handleExpand,
      clearData,
      color
    }
    return (
      <BrowseContext.Provider value={{...value}}>
        { children }
      </BrowseContext.Provider>
    )

}
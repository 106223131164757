import Arrow from '../assets/down_arrow.svg'
import Minus from '../assets/minus_icon.svg'
import Expand from '../assets/expand.svg'
export default function NoteTerminal({listFormat, blockMap, tag, identity='gospel-stack',handleClick, color={Jesus:'purple'}}) {
    // const color = color
    const expandedColors = {...color}
    Object.keys(color).map((key)=>{
        expandedColors[key+'.']=color[key]
        expandedColors[key+'"']=color[key]
        expandedColors['"'+key]=color[key]
        expandedColors['-'+key]=color[key]
        expandedColors[key.toLowerCase()]=color[key]
        expandedColors[key.toUpperCase()]=color[key]
        expandedColors[key.charAt(0).toUpperCase() + key.slice(1)]=color[key]
        return null
    })
    return (
        <div className='light-terminal terminal-body'>
            <div className='term-header'>
                {/* <span className='blue'>{identity}@</span> */}
                <h3><span>{tag.toLowerCase()}</span> <span className='green'>{'%'}</span> </h3>
                <div className='term-nav'>
                <div data-tip="clear" data-place='top' className='term-btn minus' onClick={()=>handleClick({target:{value:'min'},preventDefault:()=>{}})}><img src={Minus} alt='-'></img></div>
                <div data-tip="expand" data-place='top' className='term-btn exp' onClick={()=>handleClick({target:{value:'max'},preventDefault:()=>{}})}><img src={Expand} alt='+'></img></div>
                </div>
            </div>
            <div className='terminal-content'>
                <img 
                alt='left'
                value="prev" 
                className='term-side left-arrow'
                onClick={()=>handleClick({target:{value:'prev'},preventDefault:()=>{}})} 
                src={Arrow}></img>
                {/* <div className='term-side'>{Arrow}</div> */}
                <div className='term-center'>
                    {/* <hr className='divider'/> */}
                    {listFormat && (
                        <>
                        {blockMap && (
                            <ul className='block'>
                                    {Object.keys(blockMap).map((key) => (
                                            <li key={key}>
                                            <span className='verse-number red'>{key}</span>
                                            {color && (
                                                blockMap[key].split(' ').map((word,index)=>{
                                                    return expandedColors[word] 
                                                        ? <span className={expandedColors[word]} key={index}> {word.replace('¶','')} </span> 
                                                        : <span key={index}> {word.replace('¶','')} </span>
                                                })
                                            )}
                                            </li>
                                    ))}
                            </ul>
                        )}
                        </>
                    )}
                    {!listFormat && (
                        <>
                        {blockMap && (
                            <p className='par-block'>
                                    {Object.keys(blockMap).map((key, index) => (
                                            <span key={key}>
                                            {index!==0 && blockMap[key].includes('¶') && <><br></br><br></br><span className='indent'>{'    '}</span></>}
                                            {index===0 && <><span className='indent'>{'    '}</span></>}
                                            <span className='verse-number red'>{key}</span>
                                            {color && (
                                                blockMap[key].split(' ').map((word,index)=>{
                                                    return expandedColors[word] 
                                                        ? <span className={expandedColors[word]} key={index}> {word.replace('¶','')} </span> 
                                                        : <span key={index}> {word.replace('¶','')} </span>
                                                })
                                            )}
                                            </span>
                                    ))}
                            </p>
                        )}
                        </>
                    )}
                </div>
                <img 
                alt='right'
                className='term-side right-arrow' 
                value="next"
                onClick={()=>handleClick({target:{value:'next'},preventDefault:()=>{}})} 
                src={Arrow}></img>
            </div>
    </div>
    )
}
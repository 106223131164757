import Arrow from "../../assets/down_arrow.svg"

 export default function PageNav({page}) {
    return <div className='notebook-buttons-container bottomNav'>
        <div className='notebook-buttons'>
        
                <span className='note-btn-icons'>
                    <div className='prev' key='prev' onClick={()=>{}}><img alt='prev' className='btn-icon' src={Arrow}></img></div>
                    <span className="pagenum">{page}</span>
                    <div className='next' key='next' onClick={()=>{}}><img alt='next' className='btn-icon' src={Arrow}></img></div>
                </span>
            </div>
    </div>
 }